export interface ReviewScoreId extends String {
  _reviewScoreIdBrand: string; // ref: https://typescript-jp.gitbook.io/deep-dive/main-1/nominaltyping#intfsuno
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export function ReviewScoreId(id: string): ReviewScoreId {
  return id as any;
}

class ReviewScore {
  constructor(readonly reviewScoreId: ReviewScoreId, readonly low: number, readonly high: number) {}
}

export default ReviewScore;
