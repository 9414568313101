import fetch from 'isomorphic-unfetch';

import HttpError from '../errors/HttpError';

/**
 * fetchWithErrorThrowingを使う場合はエラーがthrowされるので必ずcatchを使うこと
 */
export default async function fetchWithErrorThrowing(uri: string, option: object = {}) {
  const res = await fetch(uri, option);
  await throwErrorByResponse(res);
  return res;
}

async function throwErrorByResponse(res: Response) {
  if (res.ok) {
    return;
  }

  const responseJson = await res.json();
  const errorMessage = responseJson.error || res.statusText;

  if (res.status >= 400 && res.status <= 599) {
    throw new HttpError(errorMessage, res.status);
  }

  // 100系や300系はAPIレスポンスとしては想定外
  throw new HttpError(`Unexpected status: ${res.status} ${errorMessage}`, res.status);
}
