import { User } from "../../models/user";
import styles from "./Header.module.scss";

type Props = {
  user: User;
  t: (val: string) => string;
  menu?: {
    title: string;
    link: string;
  }[];
  logout: () => void;
};

export const UserMenu: React.FC<Props> = ({ user, menu, t, logout }) => {
  return (
    <div className={styles.userMenuList}>
      <div className={styles.userInfo}>
        <div>
          <span
            className={`${styles.iconWamazingFilled} icon-wamazing-filled`}
          ></span>
        </div>
        <div>
          <div className={styles.userId}>{user.id}</div>
          <div className={styles.userEmail}>{user.secureUser.email}</div>
        </div>
      </div>

      <ul className={styles.userMenuItems}>
        {menu &&
          menu.map((l) => (
            <li className={styles.list} key={l.title}>
              <a
                href={l.link}
                target="_blank"
                rel="noreferrer noopener"
                className={styles.anchor}
              >
                {l.title}
              </a>
            </li>
          ))}
        <li className={styles.list}>
          <a
            href={t("links.faq")}
            target="_blank"
            rel="noreferrer noopener"
            className={styles.anchor}
          >
            {t("base.faq")}
          </a>
        </li>
        <li className={styles.list}>
          <div className={`${styles.logout} ${styles.anchor}`} onClick={logout}>
            {t("base.logout")}
          </div>
        </li>
      </ul>
    </div>
  );
};
