const DEFAULT_TEMPLATE_REGEXP = new RegExp(':([\\w]+)', 'g');
export function microTemplate({
  template,
  params,
  regexp = DEFAULT_TEMPLATE_REGEXP,
}: {
  template: string;
  params: {
    [key: string]: any;
  };
  regexp?: RegExp;
}): {
  result: string;
  params: {
    [key: string]: any;
  };
} {
  const returnParams = {
    ...params,
  };
  return {
    result: template.replace(regexp, (v: any, k: string) => {
      delete returnParams[k];

      if (!params[k]) {
        throw new Error(`params: ${k} not found`);
      }

      return params[k].toString();
    }),
    params: returnParams,
  };
}
export function numberWithCommas(num: (number | string) | Null): string {
  if (num == null) return '';

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberWithCommasAndTwoDecimals(num: (number | string) | Null): string {
  if (num == null) return '';

  return num
    .toString()
    .replace(/(\.\d{2})\d*/, '$1')
    .replace(/(\d)(?=(\d{3})+\b)/g, '$1,');
}

const REPLACE_SNAKE_TO_SNAKE_TO_CAMEL_REGEXP = '^#$%^&^';
export function snakeToCamel(str: string): string {
  return str
    .replace('__', REPLACE_SNAKE_TO_SNAKE_TO_CAMEL_REGEXP)
    .replace(/_./g, s => s.charAt(1).toUpperCase())

    .replace(REPLACE_SNAKE_TO_SNAKE_TO_CAMEL_REGEXP, '__');
}
export function snakeToHungarian(str: string): string {
  return capitalizeFirstLetter(str)
    .replace('__', REPLACE_SNAKE_TO_SNAKE_TO_CAMEL_REGEXP)
    .replace(/_./g, s => s.charAt(1).toUpperCase())

    .replace(REPLACE_SNAKE_TO_SNAKE_TO_CAMEL_REGEXP, '__');
}
export function kebabToHungarian(str: string): string {
  return capitalizeFirstLetter(str).replace(/-./g, s => s.charAt(1).toUpperCase());
}
export function capitalizeFirstLetter(str: string): string {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}
export function decapitalizeFirstLetter(str: string): string {
  return `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
}
export function containsOnlyAlphabet(str: string): boolean {
  const match = str.match(/[^A-Za-z]+/);

  return match === null;
}
