import styles from "./Header.module.scss";

type Props = {
  t: (val: string) => string;
  optionalSignupQuery?: string;
  notLoginMenu?: {
    title: string;
    link: string;
  }[];
};

export const UserNotLogInMenu: React.FC<Props> = ({
  t,
  optionalSignupQuery,
  notLoginMenu
}) => {
  const SIGNUP_PATH = "/account/auth/signup";
  const SIGNIN_PATH = "/account/auth/signin";
  const signUpUrl = (redirectUrl: string) => {
    const encodedRedirectUrl = optionalSignupQuery
      ? `${encodeURIComponent(redirectUrl)}${optionalSignupQuery}`
      : encodeURIComponent(redirectUrl);
    return `${SIGNUP_PATH}?redirect_url=${encodedRedirectUrl}`;
  };
  const signInUrl = (redirectUrl: string) => {
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);

    return `${SIGNIN_PATH}?redirect_url=${encodedRedirectUrl}`;
  };
  return (
    <div className={styles.userMenuList}>
      <ul className={styles.userMenuItems}>
      {notLoginMenu &&
        notLoginMenu.map((l) => (
          <li className={styles.list} key={l.title}>
            <a
              href={l.link}
              target="_blank"
              rel="noreferrer noopener"
              className={styles.anchor}
            >
              {l.title}
            </a>
          </li>
        ))}
        <li className={styles.list}>
          <a href={signInUrl(window.location.href)} className={styles.anchor}>
            {t("base.login")}
          </a>
        </li>
        <li className={styles.list}>
          <a href={signUpUrl(window.location.href)} className={styles.anchor}>
            {t("base.signup")}
          </a>
        </li>
        <li className={styles.list}>
          <a
            href={t("links.faq")}
            target="_blank"
            rel="noreferrer noopener"
            className={styles.anchor}
          >
            {t("base.faq")}
          </a>
        </li>
      </ul>
    </div>
  );
};
