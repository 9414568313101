import { action, observable, computed } from 'mobx';
import { persist } from 'mobx-persist';

import { ILocalStorage } from './ILocalStorage';

export type SessionTokens = {
  access_token: string;
  refresh_token: string;
  authorized_at: string;
  session_id: string;
};

export default class SessionStorage implements ILocalStorage {
  name: string = 'sessionStore';

  @persist
  @observable.ref
  accessToken: string | Null = null;

  @persist
  @observable.ref
  refreshToken: string | Null = null;

  @persist
  @observable.ref
  authorizedAt: string | Null = null;

  @persist
  @observable.ref
  sessionId: string | Null = null;

  @action
  updateTokens(tokens: SessionTokens) {
    this.accessToken = tokens.access_token;
    this.refreshToken = tokens.refresh_token;
    this.authorizedAt = tokens.authorized_at;
    this.sessionId = tokens.session_id;
  }

  @action
  clear() {
    this.accessToken = null;
    this.refreshToken = null;
    this.authorizedAt = null;
    this.sessionId = null;
  }

  @computed
  get clientType(): string | Null {
    const { refreshToken } = this;

    if (refreshToken) {
      const words: string[] = refreshToken.split('.');

      if (words[2] && words[1]) {
        const clientType = words[1];
        return clientType;
      }
    }

    return null;
  }
}
