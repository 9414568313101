import * as React from 'react';
import { inject, observer } from 'mobx-react';
import Head from 'next/head';
import dynamic from 'next/dynamic';

import Link from 'next/link';
import {
  Header,
  HeaderProps,
} from '../../../../wamazing-UI-components-react/src/components/header';
import {
  Footer,
  FooterProps,
} from '../../../../wamazing-UI-components-react/src/components/footer';
import AuthUserStore from '../../../models/mobx/stores/AuthUserStore';
import CouponStore from '../../../models/mobx/stores/CouponStore';
import FeatureArticleStore from '../../../models/mobx/stores/FeatureArticleStore';
import R from '../../../R';
import HeadFavicon from '../../common/HeadFavicon';
import { gtagSendUserId, trackPageView } from '../../../models/trackings/Tracking';
import HeadMetaHrefLang from '../../common/HeadMetaHrefLang';
import FeaturedCouponBanner from '../coupon/FeaturedCouponBanner';
import i18n from '../../../R/string/i18n';
import { runSift } from '../../../helpers/sift_helper';
import {
  getWamazingSubdomain,
  LangFormat,
  getLocale,
  isJp,
  isCn,
} from '../../../config/locales/i18n';
import {
  getUserInfo,
  getUserMenuItems,
  getUserNotLoginMenuItems,
} from '../../../helpers/user_helper';
import { LanguagesType } from '../../../../wamazing-UI-components-react/src/models/languagesType';
import { onSignOutClick } from '../../common/helpers/global_header_helper';
import pageComponent from '../../../shared/hoc/pageComponent';
import { getTranslation } from '../../../../wamazing-UI-components-react/src/libs/getTranslation';
import { separateFeatureArticleList } from '../../../helpers/page_helpers/features_helper';
import ENV from '../../../constants/ENV';
import WechatWidget from '../../common/WechatWidget';
import { isCnWebBrowser } from '../../../helpers/browser_helper';
import { isBrowser } from '../../../shared/utils/next_helper';
import { WAProduct } from '../../../../wamazing-UI-components-react/src/utils/productType';
import { OGPTypeContent, getOGPLocale } from '../../../types/OGPType';
import { DeviceContext, DeviceContextType } from '../../../models/context/DeviceContext';

type FooterType = 'big' | 'small' | 'medium';

type Props = {
  authUserStore: AuthUserStore | Null;
  couponStore?: CouponStore | null;
  featureArticleStore: FeatureArticleStore;
  footer: FooterType;
  children: React.ReactNode;
  meta: {
    image?: string | Null;
    title: string;
    description: string;
    keywords: string;
    noindex: boolean;
    canonical: string;
    type?: OGPTypeContent;
  };
  language: LanguagesType;
  t: (val: string) => void;
  productName?: WAProduct;
};

type State = {
  referrer: string;
};

const FooterBig = dynamic(() => import('./FooterBig'));

const AboutLink = () => (
  <Link href="/yado/onsen/about" className="about-header-link">
    {i18n.forFirstTimer()}
  </Link>
);

export const translation = (Component: any) => {
  return (props: any) => {
    const subdomain = getWamazingSubdomain() as string;
    const displayLang = LangFormat(subdomain);
    const { t } = getTranslation(displayLang);

    return (
      <>
        <Head>
          <title>{props.meta.title}</title>
        </Head>
        <Component language={displayLang} t={t} {...props} />
      </>
    );
  };
};

@pageComponent
@inject('featureArticleStore', 'authUserStore', 'couponStore')
@observer
class OnsenLayout extends React.Component<Props, State> {
  observer: IntersectionObserver | null;

  context!: DeviceContextType;

  static contextType = DeviceContext;

  static defaultProps = {
    footer: 'big',
    couponStore: null,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      referrer: '',
    };
    this.observer = null;
  }

  componentDidMount() {
    trackPageView(window.location.pathname, window.location.search);

    if (this.props.authUserStore) {
      runSift(this.props.authUserStore.userId);
    }
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    const userId = this.props.authUserStore?.userId;
    const { referrer } = this.state;

    if (isBrowser() && userId && !referrer && document.referrer) {
      this.setState({ referrer: document.referrer });
    }

    if (!isCn() && userId) {
      const userID = userId.toString();
      if (
        referrer !== prevState.referrer &&
        referrer.includes('/account/auth/signin?redirect_url')
      ) {
        const subdomain = getWamazingSubdomain();
        const gaId =
          ENV.ENVIRONMENT === 'production'
            ? process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID || ''
            : ENV.GA_TRACKING_IDS[subdomain];
        gtagSendUserId(userID, gaId);
      }
    }
  }

  isNotQRCodePage() {
    return (
      window.location.pathname !== '/yado/onsen/wechat/qrlink' &&
      window.location.pathname !== '/yado/onsen/wechat/subscribe'
    );
  }

  render() {
    const {
      authUserStore,
      couponStore,
      children,
      meta,
      t,
      language,
      featureArticleStore,
      productName = 'yado',
    } = this.props;
    const featureCollection = separateFeatureArticleList(featureArticleStore.featureArticleList)
      .featureList;
    const locale = getLocale();
    const ogLocale = getOGPLocale(locale);
    const isShowCouponBanner = false;
    const { isMobile } = this.context;

    const headerProps: HeaderProps = {
      lang: locale as LanguagesType,
      productName,
      user: getUserInfo(authUserStore, language),
      menu: getUserMenuItems(t, isMobile),
      notLoginMenu: getUserNotLoginMenuItems(t, isMobile),
      logout: () => onSignOutClick(authUserStore),
    };
    if (productName === 'yado') {
      headerProps.content = {
        right: <AboutLink />,
      };
    }

    const footerProps: FooterProps = {
      lang: language,
      productName,
    };

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width, maximum-scale=1.0"
          />
          <meta name="description" content={meta.description} />
          <meta name="keywords" content={meta.keywords} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:type" content={meta.type ?? OGPTypeContent.Article} />
          <meta property="og:locale" content={ogLocale} />
          <meta property="og:image" content={meta.image || R.assets.images.onsenYadoOgDefault} />
          <meta property="og:site_name" content={i18n.webYadoOnsenSitename()} />
          <meta property="og:description" content={meta.description} />
          <meta name="twitter:title" content={meta.title} />
          <meta name="twitter:description" content={meta.description} />
          <meta name="twitter:image" content={meta.image || R.assets.images.onsenYadoOgDefault} />
          {ENV.ENVIRONMENT === 'production' && !isJp() ? (
            meta.noindex && <meta name="robots" content="noindex, nofollow" />
          ) : (
            <meta name="robots" content="noindex, nofollow" />
          )}
          {meta.canonical && (
            <React.Fragment>
              <meta property="og:url" content={meta.canonical} />
              <link rel="canonical" href={meta.canonical} />
            </React.Fragment>
          )}
        </Head>
        <HeadFavicon />
        {meta.canonical && (
          <HeadMetaHrefLang path={meta.canonical.replace(/^https?:\/\/[a-z.:\d-]+?\//g, '/')} />
        )}
        <Header {...headerProps} />
        {isShowCouponBanner && couponStore && (
          <FeaturedCouponBanner authUserStore={authUserStore} couponStore={couponStore} />
        )}
        <main>{children}</main>
        {productName !== 'wamazing' && <FooterBig featureList={featureCollection} />}
        <Footer {...footerProps} />
        {isCnWebBrowser() && this.isNotQRCodePage() && <WechatWidget />}
      </>
    );
  }
}

export default translation(OnsenLayout);
