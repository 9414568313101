import fetch from 'isomorphic-unfetch';

import { signInUrl, signOutParams, registrationUrl } from '../../../shared/utils/url_helper';
import AuthUserStore from '../../../models/mobx/stores/AuthUserStore';

export function getSignInUrl(): string {
  return signInUrl(window ? window.location.href : null);
}

export function getRegistrationUrl(): string {
  return registrationUrl(window ? window.location.href : null);
}

export function onSignOutClick(authUser: AuthUserStore | Null) {
  if (!authUser) return;
  const { path } = signOutParams(window ? window.location.href : null);
  /*
   * snow 側で logout 処理
   * cookie の削除などを行うため、credentials をセット
   * また redirect はこちらでハンドリングするため manual へ
   */
  fetch(path, {
    method: 'delete',
    redirect: 'manual',
    credentials: 'include',
  }).then(() => {
    if (authUser) {
      authUser.signOut();
    }

    // signout のタイミングによって AuthUser storeが残ってしまうため、一度リロードが必要
    // signout は多々呼ばれる訳ではないので根本対応せず
    // 本来は不必要な方が望ましい
    setTimeout(() => {
      window.location.reload();
    }, 500);
  });
}
export function onSignInClick() {
  window.location.href = getSignInUrl();
}
export function onRegistrationClick() {
  window.location.href = getRegistrationUrl();
}
