import { observable, action, computed } from 'mobx';
import { FeatureArticleList } from '../../../types/response_types/FeatureArticleResponseTypes';
import { fetchFeatureArticleList } from '../../../data_sources/networks/yado-backend/FeatureArticleApi';
import { getFeatureArticleLocaleImage } from '../../../helpers/page_helpers/features_helper';

class FeatureArticleStore {
  @observable
  featureArticleList: FeatureArticleList = [];

  async loadFeatureArticleList(): Promise<void> {
    if (this.isLoaded) return;
    try {
      let featureArticleList = await fetchFeatureArticleList();
      // 手動でサムネ画像を各言語ごとに差し替え
      featureArticleList = featureArticleList.map(featureArticle => ({
        ...featureArticle,
        image: getFeatureArticleLocaleImage(featureArticle),
      }));

      this.setFeatureArticleList(featureArticleList);
    } catch (error) {
      console.warn('loadFeatureArticleList failed:', error);
    }
  }

  @action
  setFeatureArticleList(featureArticleList: FeatureArticleList) {
    this.featureArticleList = featureArticleList;
  }

  @computed
  get isLoaded(): boolean {
    return this.featureArticleList.length >= 1;
  }
}

export default FeatureArticleStore;
