const Colors = {
  transparent: 'transparent',
  waPink: '#e60675',
  pink: '#e91e63',
  black: '#151414',
  deepBlack: '#101010',
  textBlack: '#2F2F2F',
  textDarkGray: '#333333',
  white: '#ffffff',
  gray100: '#f8f8f8',
  gray200: '#e2e2e2',
  gray300: '#e8e8e8',
  gray400: '#545454',
  gray600: '#9b9b9b',
  gray800: '#252525',
  yellow: '#ffde3d',
  red: '#d0021b',
  green: '#058E4C',
  blue: '#1D9EEF',
  orange: '#FFA600',
  wineRed: '#ba2c5d',
  opacityGray600: 'rgba(0,0,0,0.7)',
  opacityGray300: 'rgba(0,0,0,0.4)',
  opacityGray100: 'rgba(0,0,0,0.1)',
  onsenBlack: '#2F2F2F',
  onsenWhite: '#ffffff',
  onsenBlue: '#2187f2',
  onsenPink: '#e91e63',
  onsenRed: '#DD3434',
  onsenDarkRed: '#c33128',
  onsenGray800: '#686868',
  onsenGray400: '#B3B3B3',
  onsenGray300: '#DDDDDD',
  onsenGray200: '#EEEEEE',
  onsenGray100: '#F7F7F7',
  onsenBackground: '#FAFAFA',
  onsenLightBlue: '#eaf1f8',
  onsenDarkGreen: '#198219',
  onsenDarkOrange: '#e35c14',
  onsenDarkYellow: '#f3a52d',
  onsenPriceBlack: '#000000',
  onsenBenefitPink: '#F06083',
  onsenBlueButton: '#4088EA',
  onsenGrayBorder: '#dce5ee',
  opacityDarkGreen10: 'rgba(25, 130, 25, 0.1)',
  datePickerArrowGray: '#cecece',
  onsenLabelRed: '#ec3636',
  onsenErrorRed: '#FF0000',
  formInputGrayBorder: '#e5e5e5',
  formDivider: '#E9E9E9',
  onsenFooterHoverBlue: '#007bff',
  onsenLimitedYellow: '#D99301',
  pjtBlue: '#1e2083',
  pjtDeepBlue: '#1e3050',
  pjtBackgroundBluish: '#f4f9fd',
  pjtBorder: '#c9cdd1',
  pjtBgGrey: '#f5f5f5',
  greenBoxBg: '#e8f4e3',
  lightblueBorder: '#3577C0',
  supportBorderGreen: '#27c668',
  supportBackgroundGreen: '#defaef',
};

const Themes: {
  [key: number]: {
    [key: number]: any;
    [key: string]: any;
  };
  [key: string]: {
    [key: number]: any;
    [key: string]: any;
  };
} = {
  text: {
    default: Colors.textBlack,
    primary: Colors.pink,
    secondary: Colors.blue,
    success: Colors.green,
    info: Colors.blue,
    warning: Colors.orange,
    danger: Colors.red,
    muted: Colors.gray300,
    light: Colors.gray100,
    white: Colors.white,
    dark: Colors.gray800,
    normal: Colors.gray400,
  },
  border: {
    default: Colors.gray200,
    primary: Colors.pink,
    secondary: Colors.blue,
    success: Colors.green,
    info: Colors.blue,
    warning: Colors.orange,
    danger: Colors.red,
    muted: 'transparent',
    light: Colors.gray100,
    white: Colors.white,
    dark: Colors.gray800,
  },
  background: {
    default: Colors.white,
    primary: Colors.pink,
    secondary: Colors.blue,
    success: Colors.green,
    info: Colors.blue,
    warning: Colors.orange,
    danger: Colors.red,
    muted: Colors.gray300,

    light: Colors.gray100,
    white: Colors.white,
    dark: Colors.gray800,
  },
};
const Breakpoints = {
  smMin: '576px',
  mdMin: '768px',
  lgMin: '992px',
  xlMin: '1200px',

  xsMax: '575.98px',

  smMax: '767.98px',
  mdMax: '991.98px',
  lgMax: '1199.98px',
};

const PhotoCategoryBreakpoints = {
  mdMin: '820px',
  lgMin: '1120px',
  xlMin: '1299px',
};

const convertBreakpoint = (breakpoints: string) => {
  return parseInt(breakpoints, 10);
};

export { Colors, Themes, Breakpoints, PhotoCategoryBreakpoints, convertBreakpoint };
