import AuthUserStore from '../models/mobx/stores/AuthUserStore';
import ExchangeRateStore from '../models/mobx/stores/ExchangeRateStore';
import { LocaleType } from '../legacy/types/LocaleType';
import SearchJsonStore from '../models/mobx/stores/SearchJsonStore';
import CouponStore from '../models/mobx/stores/CouponStore';
import YadoPricesStore from '../models/mobx/stores/YadoPricesStore';
import FilterConditionStore from '../models/mobx/stores/FilterConditionStore';
import FeatureArticleStore from '../models/mobx/stores/FeatureArticleStore';

type StoresProps = {
  locale: LocaleType;
};

export default class Stores {
  authUserStore: AuthUserStore;

  exchangeRateStore: ExchangeRateStore;

  searchJsonStore: SearchJsonStore;

  couponStore: CouponStore;

  yadoPricesStore: YadoPricesStore;

  filterConditionStore: FilterConditionStore;

  featureArticleStore: FeatureArticleStore;

  constructor({ locale }: StoresProps) {
    this.authUserStore = new AuthUserStore();
    this.exchangeRateStore = new ExchangeRateStore(locale);
    this.searchJsonStore = new SearchJsonStore();
    this.couponStore = new CouponStore(this.authUserStore);
    this.yadoPricesStore = new YadoPricesStore(this.couponStore, this.exchangeRateStore);
    this.filterConditionStore = new FilterConditionStore(
      this.exchangeRateStore,
      this.yadoPricesStore,
    );
    this.featureArticleStore = new FeatureArticleStore();
  }
}
