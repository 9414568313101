import { isBrowser } from '../../shared/utils/next_helper';

const KEY = 'viewedYados';
const MAX_YADOS = 10;

const yadoIDFilter = (yadoID: string | undefined) =>
  !!(yadoID && typeof yadoID === 'string' && yadoID !== 'undefined');

export function getViewedYados(): string[] {
  if (isBrowser() && window.localStorage) {
    const jsonStr = window.localStorage.getItem(KEY);
    if (jsonStr) {
      const formStorage = JSON.parse(jsonStr);

      if (!Array.isArray(formStorage)) {
        return [];
      }

      const cleanFormStorage = formStorage.filter(yadoIDFilter);
      if (cleanFormStorage.length !== formStorage.length) {
        saveViewedYadosToStorage(cleanFormStorage);
      }

      return cleanFormStorage;
    }
  }

  return [];
}

function saveViewedYadosToStorage(yados: string[]) {
  if (isBrowser() && window.localStorage) {
    const cleanYados = yados.filter(yadoIDFilter);
    window.localStorage.setItem(KEY, JSON.stringify(cleanYados));
  }
}

export function addViewedYado(yadoId: string) {
  const yados = getViewedYados() ?? [];

  if (yadoIDFilter(yadoId) && !yados.includes(yadoId)) {
    if (yados.length >= MAX_YADOS) {
      const updatedYados = yados.slice(1);
      updatedYados.push(yadoId);

      saveViewedYadosToStorage(updatedYados);
    } else {
      yados.push(yadoId);

      saveViewedYadosToStorage(yados);
    }
  }
}

export function removeViewedYado(yadoID: string) {
  const yadoIDs = getViewedYados();

  if (yadoIDFilter(yadoID)) {
    saveViewedYadosToStorage(yadoIDs.filter(id => id !== yadoID));
  }
}
