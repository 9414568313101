export type FacilityServiceId =
  | 'freeWifi'
  | 'frontDesk24'
  | 'concierge'
  | 'cashWithdrawal'
  | 'moneyExchange'
  | 'roomService24'
  | 'petFriendly'
  | 'selfLaundry'
  | 'laundryService'
  | 'smokingArea'
  | 'indoorSwimmingPool'
  | 'outdoorSwimmingPool'
  | 'tableTennis'
  | 'amusementArcade'
  | 'karaoke'
  | 'fitness'
  | 'tennisCourt'
  | 'massage'
  | 'beautySalon'
  | 'giftShop'
  | 'vendingMachine'
  | 'skiStoreroom';

class FacilityService {
  constructor(
    readonly facilityServiceId: FacilityServiceId,
    readonly facilityServiceName: string,
  ) {}
}

export default FacilityService;
