import Cookies from 'universal-cookie';
import addHours from 'date-fns/addHours';
import addYears from 'date-fns/addYears';
import { getDomainForCookie } from '../shared/utils/url_helper';

export const COOKIE_REFRESH_TOKEN = 'token';
const COOKIE_ACCESS_TOKEN = 'accessToken';
const COOKIE_WECHAT_STATE = 'wechatState';
export const COOKIE_SESSION_ID = 'sessionId';

const cookies = new Cookies();

export default cookies;

export function setWechatStateCookie(wechatState: string) {
  // CSRF防止用にstateにランダムの文字列をセット
  // 参考: https://developers.weixin.qq.com/doc/oplatform/en/Website_App/WeChat_Login/Wechat_Login.html
  // こちらのトークンはCSRF対策のためのものなので
  // Expireを1時間にしています。
  const expires = addHours(new Date(), 1);
  const domain = getDomainForCookie();

  if (domain) {
    cookies.set(COOKIE_WECHAT_STATE, wechatState, {
      path: '/yado',
      expires,
      domain,
    });
  }
}

export function removeCookieWechatState() {
  cookies.remove(COOKIE_WECHAT_STATE);
}

export function getCookieWechatState(): string | Null {
  return cookies.get(COOKIE_WECHAT_STATE);
}

export function getCookieRefreshToken(): string | Null {
  return cookies.get(COOKIE_REFRESH_TOKEN);
}

export function setCookieRefreshToken(refreshToken: string) {
  // account-webの cookie の有効期限が20年だったため
  const expires = addYears(new Date(), 20);
  const domain = getDomainForCookie();

  if (domain) {
    cookies.set(COOKIE_REFRESH_TOKEN, refreshToken, {
      path: '/',
      expires,
      domain,
    });
  }
}
export function getCookieAccessToken(): string | Null {
  return cookies.get(COOKIE_ACCESS_TOKEN);
}
export function setCookieAccessToken(accessToken: string) {
  const expires = addYears(new Date(), 1); // account-webの cookie の有効期限が1年のため
  const domain = getDomainForCookie();

  if (domain) {
    cookies.set(COOKIE_ACCESS_TOKEN, accessToken, {
      path: '/',
      expires,
      domain,
    });
  }
}
export function removeCookieRefreshToken() {
  const domain = getDomainForCookie();

  if (domain) {
    cookies.remove(COOKIE_REFRESH_TOKEN, {
      path: '/',
      domain,
    });
  }
}
export function removeCookieAccessToken() {
  const domain = getDomainForCookie();

  if (domain) {
    cookies.remove(COOKIE_ACCESS_TOKEN, {
      path: '/',

      domain,
    });
  }
}

export function getCookieSessionId(): string | Null {
  return cookies.get(COOKIE_SESSION_ID);
}

export function setCookieSessionId(sessionId: string) {
  const expires = addYears(new Date(), 1); // account-webの cookie の有効期限が1年のため
  const domain = getDomainForCookie();

  if (domain) {
    cookies.set(COOKIE_SESSION_ID, sessionId, {
      path: '/',
      expires,
      domain,
    });
  }
}

export function removeCookieSessionId() {
  const domain = getDomainForCookie();

  if (domain) {
    cookies.remove(COOKIE_SESSION_ID, {
      path: '/',

      domain,
    });
  }
}
