import * as React from 'react';
import { withRouter, NextRouter } from 'next/router';
import { Provider } from 'mobx-react';
import isbot from 'isbot';
import isMobile from 'ismobilejs';
import Modal from 'react-modal';
import { NextPageContext } from 'next';

import { bootstrap, initStores, initLocale } from '../../root/Bootstrap';
import Stores from '../../root/Stores';
import { createHrefOnServer } from '../utils/url_helper';
import { isServer } from '../utils/next_helper';
import { trackEvent } from '../../models/trackings/Tracking';
import { pageEvents } from '../../models/trackings/trackingEvents';
import { DeviceContext } from '../../models/context/DeviceContext';

type Props = {
  stores: Stores | Null;
  resultSsrJson: any;
  userAgent: string;
  isBot: boolean;
  href: string | Null;
  router: NextRouter;
};

type State = {
  href: string;
  stores: Stores | Null;
  userAgent: string;
};

function pageComponent(WrappedComponent: any): any {
  class PageComponent extends React.Component<Props, State> {
    static async getInitialProps(context: NextPageContext) {
      const { req } = context;

      if (req) {
        const userAgent = req?.headers['user-agent'];
        const href = createHrefOnServer(req);
        const locale = initLocale(href);
        const stores = initStores(locale);
        const initialProps = await PageComponent.getWrappedComponentInitialProps(context);
        return {
          ...initialProps,
          stores,
          href,
          userAgent,
        };
      }

      const initialProps = await PageComponent.getWrappedComponentInitialProps(context);
      return {
        ...initialProps,
      };
    }

    static async getWrappedComponentInitialProps(context: NextPageContext): Promise<object> {
      if (WrappedComponent.getInitialProps) {
        const initialProps = await WrappedComponent.getInitialProps(context);
        return initialProps;
      }

      return {};
    }

    constructor(props: Props) {
      super(props);
      this.state = {
        href: '',
        stores: null,
        userAgent: '',
      };

      if (isServer()) {
        this.state = {
          href: props.href ?? '',
          stores: props.stores,
          userAgent: props.userAgent,
        };
      }

      Modal.setAppElement('#__next');
    }

    async componentDidMount() {
      const { href, pathname } = window.location;
      const { userAgent } = window.navigator;
      const locale = initLocale(href);
      const stores = initStores(locale);
      this.setState({
        href,
        stores,
        userAgent,
      });
      await bootstrap({
        stores,
        href,
        pathname,
      });

      if (window.location.pathname.startsWith('/yado/onsen')) {
        trackEvent(pageEvents.ONSEN_PAGE_VIEW);
      }
    }

    render() {
      const { stores, userAgent, href } = this.state;

      if (!stores) {
        return <div />;
      }

      const isMobileResult = isMobile(userAgent);
      const isBotResult = isbot(userAgent);

      // const InjectedComponent = inject(
      //   'authUserStore',
      //   'couponStore',
      //   'exchangeRateStore',
      //   'searchJsonStore',
      //   'yadoPricesStore',
      //   'filterConditionStore',
      //   'featureArticleStore',
      // )(WrappedComponent);

      return (
        <Provider
          authUserStore={stores.authUserStore}
          couponStore={stores.couponStore}
          exchangeRateStore={stores.exchangeRateStore}
          searchJsonStore={stores.searchJsonStore}
          yadoPricesStore={stores.yadoPricesStore}
          filterConditionStore={stores.filterConditionStore}
          featureArticleStore={stores.featureArticleStore}
        >
          <DeviceContext.Provider
            value={{ isMobile: isMobileResult, isBot: isBotResult, userAgent }}
          >
            <React.Fragment>
              <WrappedComponent
                {...this.props}
                userAgent={userAgent}
                href={href}
                isBot={isBotResult}
                isMobile={isMobileResult}
              />
            </React.Fragment>
          </DeviceContext.Provider>
        </Provider>
      );
    }
  }

  return withRouter(PageComponent);
}

export default pageComponent;
