import * as React from 'react';
import Router from 'next/router';
import R from '../../R';
import { trackEvent } from '../../models/trackings/Tracking';
import { actionEvents } from '../../models/trackings/trackingEvents';

function onClickWechatWidget() {
  trackEvent(actionEvents.WECHAT_WIDGET_CLICKED);

  // eslint-disable-next-line no-alert
  const confirmed = window.confirm(R.i18n.webYadoAskForWechatSubscription());

  if (confirmed) {
    trackEvent(actionEvents.WECHAT_WIDGET_CONFIRMED);
    Router.push('/yado/onsen/wechat/subscribe').then(() => {
      window.scrollTo(0, 0);
    });
  }
}

const WechatWidget = () => (
  <button type="button" onClick={onClickWechatWidget}>
    <img src={R.assets.images.commonWechatLogo} alt="wechat" />
    <style jsx>
      {`
        button {
          position: fixed;
          bottom: 20px;
          left: 40px;
          width: 60x;
          height: 60px;
          z-index: 10000;
          border-radius: 50%;
          outline: none;
          border: 0;
          padding: 0;
        }
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      `}
    </style>
  </button>
);

export default WechatWidget;
