import { isMobileResult } from 'ismobilejs';
import { LanguagesType } from '../../wamazing-UI-components-react/src/models/languagesType';
import {
  SecureCreditCardResponse,
  SecureUserResponse,
  User,
  UserMenuList,
} from '../../wamazing-UI-components-react/src/models/user';
import { SecureUserProfileResponse, TravelResponse } from '../models/account/User';
import AuthUserStore from '../models/mobx/stores/AuthUserStore';
import i18n from '../R/string/i18n';

export const getUserMenuItems = (t: (val: string) => any, isMobile: isMobileResult) => {
  const userMenuItems: UserMenuList[] = [
    ...(isMobile.phone
      ? [
          {
            title: i18n.forFirstTimer(),
            link: '/yado/onsen/about',
          },
        ]
      : []),
    {
      title: t('base.orders'),
      link: t('links.orders'),
    },
    {
      title: t('base.coupon'),
      link: t('links.coupons'),
    },
  ];
  return userMenuItems;
};

export const getUserNotLoginMenuItems = (t: (val: string) => any, isMobile: isMobileResult) => {
  const userMenuItems: UserMenuList[] = [
    ...(isMobile.phone
      ? [
          {
            title: i18n.forFirstTimer(),
            link: '/yado/onsen/about',
          },
        ]
      : []),
  ];
  return userMenuItems;
};

export const getUserInfo = (authUserStore: AuthUserStore | Null, language: LanguagesType) => {
  let userInfo: User | undefined;
  let newSecureCreditCards: SecureCreditCardResponse[] = [];
  let newSecureUser: SecureUserResponse;

  if (authUserStore && authUserStore.isSignedIn) {
    const { secureUser, secureGmoCreditCards } = authUserStore;

    if (secureGmoCreditCards) {
      newSecureCreditCards = secureGmoCreditCards.map(item => ({
        brand: item.brand || '',
        holderName: item.holder_name || '',
        lowerFourDigits: item.lower_four_digits,
        expMonth: Number(item.exp_month),
        expYear: Number(item.exp_year),
        id: item.id,
        primary: item.primary,
      }));
    }

    newSecureUser = {
      accessToken: secureUser?.access_token as string,
      refreshToken: secureUser?.refresh_token as string,
      authorizedAt: new Date(),
      confirmedAt: new Date(),
      email: secureUser?.email as string,
    };

    userInfo = {
      id: authUserStore.userId?.toString() as string,
      language,
      createdAt: authUserStore.createdAt,
      currentTravel: authUserStore.currentTravel as TravelResponse[],
      secureUser: newSecureUser,
      secureCreditCards: newSecureCreditCards,
      secureUserProfile: authUserStore.secureUserProfile as SecureUserProfileResponse,
    };
  }
  return userInfo;
};
