import { getTranslation } from "../../libs/getTranslation";
import { LanguagesType } from "../../models/languagesType";
import { displayLinks, DisplayLinksType } from "../../utils/displayLinks";
import { convertToUtmParams, serviceLink } from "../../utils/helper";
import { WAProduct } from "../../utils/productType";
import styles from "./Footer.module.scss";
import { FooterList } from "./FooterList";

export type FooterProps = {
  lang: LanguagesType;
  productName: WAProduct;
};

export const Footer: React.FC<FooterProps> = ({ lang, productName }) => {
  const { t } = getTranslation(lang);

  const displayedLinks: DisplayLinksType = displayLinks[lang as LanguagesType];

  return (
    <footer
      className={`${styles.footer} ${styles[`${lang}`]} ${
        productName === "activity" ? styles.activity : ""
      }`}
    >
      <div className={styles.footerListContainer}>
        <div>
          <h2 className={styles.footerListTitle}>
            {t("footer.about_wamazing")}
          </h2>
          <ul className={styles.footerList}>
            <FooterList
              href={t("links.corporate")}
              title={t("base.company_info")}
            />
            <FooterList
              href="https://www.wamazing.jp/terms/user"
              title={t("base.terms_of_use")}
            />
            <FooterList
              href="https://www.wamazing.jp/terms/privacy"
              title={t("base.privacy_policy")}
            />
            <FooterList href={t("links.faq")} title={t("base.faq")} />
            <FooterList
              href={t("links.contact")}
              title={t("base.contact_us")}
            />
            <FooterList
              href="https://www.wamazing.jp/terms/commerce_act"
              title={t("base.transcription")}
            />
          </ul>
        </div>

        <div>
          <h2 className={styles.footerListTitle}>
            {t("footer.wamazing_services")}
          </h2>
          <ul className={styles.footerList}>
            {displayedLinks.yado && (
              <FooterList
                href={serviceLink(t("links.yado"), productName, "footer")}
                title={t("base.wamazing")}
                productName={t("product.stay")}
              />
            )}
            {displayedLinks.activity && (
              <FooterList
                href={serviceLink(t("links.activity"), productName, "footer")}
                title={t("base.wamazing")}
                productName={t("product.play")}
              />
            )}
            {displayedLinks.snow && (
              <FooterList
                href={serviceLink(t("links.snow"), productName, "footer")}
                title={t("base.wamazing")}
                productName={t("product.snow")}
              />
            )}
            {displayedLinks.ticket && (
              <FooterList
                href={serviceLink(t("links.ticket"), productName, "footer")}
                title={t("base.wamazing")}
                productName={t("product.transport")}
              />
            )}
            {displayedLinks.kaimono && (
              <FooterList
                href={serviceLink(t("links.kaimono"), productName, "footer")}
                title={t("base.wamazing")}
                productName={t("product.shopping")}
              />
            )}
            {displayedLinks.discover && (
              <FooterList
                href={serviceLink(t("links.discover"), productName, "footer")}
                title={t("base.wamazing")}
                productName={t("product.discover")}
              />
            )}
            {displayedLinks.insurance && (
              <FooterList
                href={serviceLink(t("links.insurance"), productName, "footer")}
                title={t("base.wamazing")}
                productName={t("product.insurance")}
              />
            )}
          </ul>
        </div>

        {displayedLinks.app && (
          <div>
            <h2 className={styles.footerListTitle}>{t("footer.app")}</h2>
            <ul className={styles.footerList}>
              {(lang === "zh_hk" || lang === "zh_tw") && (
                <>
                  <FooterList
                    href={serviceLink(
                      t("links.appManual"),
                      productName,
                      "footer"
                    )}
                    title={t("base.appManual")}
                  />
                  <FooterList
                    href={serviceLink(t("links.sim"), productName, "footer")}
                    title="SIM"
                  />
                </>
              )}
              <FooterList
                href={`https://app.adjust.com/oq033b2?utm_source=${convertToUtmParams(
                  productName
                )}&utm_medium=referral&utm_campaign=footer`}
                title="iPhone"
              />
              <FooterList
                href={`https://app.adjust.com/oq033b2?utm_source=${convertToUtmParams(
                  productName
                )}&utm_medium=referral&utm_campaign=footer`}
                title="Android"
              />
            </ul>
          </div>
        )}
        <div>
          {(t("links.facebook") !== "" || t("links.instagram") !== "") && (
            <h2 className={styles.footerListTitle}>{t("footer.sns")}</h2>
          )}
          <ul className={`${styles.footerList} ${styles.sns}`}>
            {t("links.instagram") && (
              <FooterList href={t("links.instagram")} ariaLabel="Instagram">
                <span className={`icon-instagram`}></span>
              </FooterList>
            )}
            {t("links.facebook") && (
              <FooterList href={t("links.facebook")} ariaLabel="Facebook">
                <span className={`icon-facebook`}></span>
              </FooterList>
            )}
          </ul>
        </div>
      </div>
      <div className={styles.copyright}>
        <div>{t("footer.travel_agency_registration")}</div>
        <span className={styles.small}>
          &copy; WAmazing, Inc. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};
