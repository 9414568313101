import { subDays } from 'date-fns';
import { CancellationPolicy, RoomPlan } from '../types/response_types/RoomPlanResponseTypes';
import { PrivateBath, YadoDetailJson } from '../types/response_types/YadoResponseTypes';
import R from '../R';

export function isJalanYado(yadoId: string | Null): boolean {
  return yadoId?.charAt(0) === 'j';
}

export function getCancellationFreeDate(
  checkinDate: string,
  policyObj: CancellationPolicy | Null,
  now: Date = new Date(),
): Date | Null {
  if (policyObj == null || policyObj.policies.length === 0) return null;
  // 日本時間のチェックイン日
  const checkinDateJST = new Date(`${checkinDate}T00:00:00+09:00`);
  const chargedPeriod = policyObj.policies
    .map(policy => policy.period)
    .sort((periodA, periodB) => periodB - periodA)[0];
  if (!chargedPeriod) return null;
  // 日本時間のキャンセルフリー日時
  const cancellationFreeTill = subDays(checkinDateJST, chargedPeriod);

  if (now >= cancellationFreeTill) return null;

  return subDays(new Date(checkinDate), chargedPeriod + 1);
}

export function getCancellationFreePlans(checkinDate: string, roomPlans: RoomPlan[]) {
  return roomPlans.filter(
    roomPlan => !!getCancellationFreeDate(checkinDate, roomPlan.plan.cancellationPolicy),
  );
}

function hasAnyFreeCancellation(checkinDate: string, roomPlans: RoomPlan[]) {
  return roomPlans.some(
    roomPlan => !!getCancellationFreeDate(checkinDate, roomPlan.plan.cancellationPolicy),
  );
}

export function hasFreeCancellationAvailable(
  roomPlans: RoomPlan[] | RoomPlan,
  checkinDate: string,
) {
  if (Array.isArray(roomPlans)) {
    return hasAnyFreeCancellation(checkinDate, roomPlans);
  }
  return !!getCancellationFreeDate(checkinDate, roomPlans.plan.cancellationPolicy);
}

export function hasReservablePrivateBath(privateBaths: PrivateBath[]): boolean {
  return privateBaths.some(pb => pb.reservationMethod === 'need_reservation_in_advance');
}

export function hasAnyReservablePrivateBath(privateBaths: PrivateBath[]): boolean {
  return privateBaths.some(
    ({ reservationMethod }) =>
      reservationMethod === 'need_reservation_on_the_day' ||
      reservationMethod === 'need_reservation_in_advance',
  );
}

export function hasPrivateBath(privateBaths: PrivateBath[]): boolean {
  return privateBaths.some(pb => pb.reservationMethod);
}

// 6点以上の場合に表示
export function evaluationLabel(rate: number): string | Null {
  if (rate >= 8) {
    return R.i18n.webYadoEvaluationVeryGood();
  }

  if (rate >= 6) {
    return R.i18n.webYadoEvaluationFariyGood();
  }

  return null;
}

export function hasFreePrivateBath(privateBaths: PrivateBath[]): boolean {
  return privateBaths.some(pb => pb.fee === 0 || !pb.fee);
}

export function hasPayToEnterPrivateBath(privateBaths: PrivateBath[]): boolean {
  return privateBaths.some(({ fee }) => fee && fee > 0);
}

export function getDinnerSelectable(yado: YadoDetailJson): boolean {
  return yado.dinners.length > 1;
}
