import React from 'react';
import Head from 'next/head';
import R from '../../R';

export default () => (
  <Head>
    <link href={R.assets.images.faviconsAppleTouchIcon} rel="apple-touch-icon" sizes="180x180" />
    <link href={R.assets.images.faviconsManifest} rel="manifest" />
    <link color="#5bbad5" href={R.assets.images.faviconsSafariPinnedTab} rel="mask-icon" />
    <meta content="#ffffff" name="theme-color" />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="57x57"
      href={R.assets.images.faviconsAppleTouchIcon57x57}
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="114x114"
      href={R.assets.images.faviconsAppleTouchIcon114x114}
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="72x72"
      href={R.assets.images.faviconsAppleTouchIcon72x72}
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="144x144"
      href={R.assets.images.faviconsAppleTouchIcon144x144}
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="60x60"
      href={R.assets.images.faviconsAppleTouchIcon60x60}
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="120x120"
      href={R.assets.images.faviconsAppleTouchIcon120x120}
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="76x76"
      href={R.assets.images.faviconsAppleTouchIcon76x76}
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="152x152"
      href={R.assets.images.faviconsAppleTouchIcon152x152}
    />
    <link
      rel="icon"
      type="image/png"
      href={R.assets.images.faviconsFavicon196x196}
      sizes="196x196"
    />
    <link rel="icon" type="image/png" href={R.assets.images.faviconsFavicon96x96} sizes="96x96" />
    <link rel="icon" type="image/png" href={R.assets.images.faviconsFavicon32x32} sizes="32x32" />
    <link rel="icon" type="image/png" href={R.assets.images.faviconsFavicon16x16} sizes="16x16" />
    <link rel="icon" type="image/png" href={R.assets.images.faviconsFavicon128} sizes="128x128" />
    <meta name="application-name" content="&nbsp;" />
    <meta name="msapplication-TileColor" content="#FFFFFF" />
    <meta name="msapplication-TileImage" content="mstile-144x144.png" />
    <meta name="msapplication-square70x70logo" content="mstile-70x70.png" />
    <meta name="msapplication-square150x150logo" content="mstile-150x150.png" />
    <meta name="msapplication-wide310x150logo" content="mstile-310x150.png" />
    <meta name="msapplication-square310x310logo" content="mstile-310x310.png" />
  </Head>
);
