import SentryReporter from '../../../models/trackings/SentryReporter';
import { getAcceptLanguage } from '../../../config/locales/i18n';
import {
  FeatureArticleList,
  FeatureArticleDetail,
} from '../../../types/response_types/FeatureArticleResponseTypes';
import fetchWithErrorThrowing from '../../../shared/utils/fetchWithErrorThrowing';
import { getReplaceBaseURI } from '../../../shared/utils/url_helper';

const featureArticlePath = '/api/v1/feature_articles';

export async function fetchFeatureArticleList(): Promise<FeatureArticleList> {
  const baseURI = getReplaceBaseURI();
  const uri = `${baseURI}${featureArticlePath}`;
  const featureArticleList: FeatureArticleList = await fetchWithErrorThrowing(uri, {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  })
    .then(res => res.json())
    .catch(error => {
      SentryReporter.captureException(`Failed to fetch featureArticleList ${uri} ${error.message}`);
      return null;
    });
  return featureArticleList;
}

export async function fetchFeatureArticleDetail(
  featureArticleId: string,
): Promise<FeatureArticleDetail | Null> {
  const baseURI = getReplaceBaseURI();
  const uri = `${baseURI}${featureArticlePath}/${featureArticleId}`;
  const featureArticleDetailResponse = await fetchWithErrorThrowing(uri, {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  });
  const text = await featureArticleDetailResponse.text();

  try {
    const featureArticleDetail: FeatureArticleDetail | Null = JSON.parse(text);
    return featureArticleDetail;
  } catch (error) {
    SentryReporter.captureException(
      `Failed to fetch featureArticleDetail ${uri} ${
        (error as Error).message
      }, input data: ${text}`,
    );
    return null;
  }
}
