import { observable, computed, action } from 'mobx';
import CouponStore, { getLowestDiscountedPriceWithCoupons } from './stores/CouponStore';
import { YadoDetailJson } from '../../types/response_types/YadoResponseTypes';

export default class YadoPrice {
  @observable
  priceIncludingTax: number | Null = null;

  @observable
  hasTwoMeals: boolean = false;

  @observable
  isLoading: boolean = false;

  @observable
  isLoaded: boolean = false;

  @observable
  popularRoomPlanIds: string[] = [];

  constructor(
    readonly yadoId: string,
    readonly areaSlug: string | Null,
    readonly checkinDate: string | Null,
    readonly couponStore: CouponStore,
    readonly yadoDetailJson: YadoDetailJson | Null,
  ) {}

  @action
  setPriceIncludingTax(priceIncludingTax: number | null) {
    this.priceIncludingTax = priceIncludingTax;
  }

  @action
  setHasTwoMeals(hasTwoMeals: boolean) {
    this.hasTwoMeals = hasTwoMeals;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setIsLoaded(isLoaded: boolean) {
    this.isLoaded = isLoaded;
  }

  @action
  setPopularRoomPlanIds(roomPlanIdStrings: string[]) {
    const roomPlanIds = roomPlanIdStrings.map(string =>
      string.replace(/RoomPlanId\((.*)\)/, 'l$1'),
    );
    this.popularRoomPlanIds = roomPlanIds;
  }

  @computed
  get isSoldOut() {
    return this.isLoaded && this.priceIncludingTax == null;
  }

  @computed
  get discountedPriceIncludingTax(): number | Null {
    return getLowestDiscountedPriceWithCoupons(
      this.priceIncludingTax,
      this.couponStore.coupons,
      this.yadoId,
      this.areaSlug,
      this.checkinDate,
    );
  }
}
