import { observable, ObservableMap, action, computed } from 'mobx';
import { PriceSearchApiParams } from '../../../shared/utils/search_helper';
import { chunkArray } from '../../../shared/utils/array';
import { wait } from '../../../legacy/shared/utils/async';
import {
  fetchYadoPrices,
  PopularYadoRoomPlan,
} from '../../../data_sources/networks/yado-backend/YadoApi';
import CouponStore from './CouponStore';
import YadoPrice from '../YadoPrice';
import ExchangeRateStore from './ExchangeRateStore';
import { YadoDetailJson } from '../../../types/response_types/YadoResponseTypes';

export type YadoPriceMap = ObservableMap<string, YadoPrice>;

export default class YadoPricesStore {
  @observable.ref
  yadoPriceMap: YadoPriceMap = new ObservableMap({});

  constructor(readonly couponStore: CouponStore, readonly exchangeRateStore: ExchangeRateStore) {}

  @action
  clearYadoPriceMap() {
    this.yadoPriceMap = new ObservableMap({});
  }

  @action
  private initYadoPrices(yados: YadoDetailJson[] | Null, checkinDate: string | Null) {
    const yadoIds = yados?.map(y => y.id) || [];
    this.yadoPriceMap = yadoIds.reduce((acc, yadoId) => {
      const yado = yados?.find(y => y.id === yadoId);
      const yadoDetailJson = yados?.find(y => y.id === yadoId);
      return acc.set(
        yadoId,
        new YadoPrice(yadoId, yado?.area?.areaSlug, checkinDate, this.couponStore, yadoDetailJson),
      );
    }, new ObservableMap({}));
  }

  private setLoadingYadoPrices(yadoIds: string[]) {
    yadoIds.forEach(yadoId => {
      this.findYadoPrice(yadoId)?.setIsLoading(true);
    });
  }

  private async loadYadoPrices(yadoIds: string[], searchParams: PriceSearchApiParams) {
    const prices = await fetchYadoPrices(yadoIds, searchParams);
    yadoIds.forEach(yadoId => {
      const priceData = prices.find(p => p.yadoId === yadoId);
      const price = priceData?.priceIncludingTax;
      const hasTwoMeals = priceData?.hasTwoMeals;
      const yadoPrice = this.findYadoPrice(yadoId);
      if (price != null) {
        yadoPrice?.setPriceIncludingTax(price);
      } else {
        yadoPrice?.setPriceIncludingTax(null);
      }

      yadoPrice?.setHasTwoMeals(!!hasTwoMeals);

      yadoPrice?.setIsLoaded(true);
      yadoPrice?.setIsLoading(false);
    });
  }

  @action
  async loadYadoPricesWithDelay(
    yados: YadoDetailJson[] | Null,
    searchParams: PriceSearchApiParams,
  ): Promise<void> {
    const yadoIds = yados?.map(y => y.id) || [];
    const MAX_ID_SIZE = 20;
    const WAIT_MSEC = 1000;
    this.initYadoPrices(yados, searchParams.checkin_date);
    this.setLoadingYadoPrices(yadoIds);

    if (yadoIds.length > MAX_ID_SIZE) {
      const chunkedYadoIds = chunkArray(yadoIds, MAX_ID_SIZE);
      chunkedYadoIds.forEach(async (ids, index) => {
        if (index !== 0) {
          await wait(WAIT_MSEC);
        }
        await this.loadYadoPrices(ids, searchParams);
      });
    } else {
      await this.loadYadoPrices(yadoIds, searchParams);
    }
  }

  @action
  setPopularRoomPlanIds(popularYadoRoomPlans: PopularYadoRoomPlan[]) {
    popularYadoRoomPlans.forEach(popularYadoRoomPlan => {
      const yadoPrice = this.findYadoPrice(popularYadoRoomPlan.yado.id);
      if (!yadoPrice) return;
      yadoPrice.setPopularRoomPlanIds(popularYadoRoomPlan.popularRoomPlanIds);
    });
  }

  findYadoPrice(yadoId: string): YadoPrice | Null {
    return this.yadoPriceMap.get(yadoId);
  }

  @computed
  get yadoIds(): string[] {
    return [...this.yadoPriceMap.keys()];
  }

  @computed
  get isAllLoaded(): boolean {
    return this.yadoIds.every(yadoId => this.findYadoPrice(yadoId)?.isLoaded === true);
  }
}
