import { isBrowser } from '../../shared/utils/next_helper';

const STORAGE_KEY = 'searchJsons';

const storage = isBrowser() ? window.sessionStorage : null;

export function getSearchJson(): any | Null {
  const item = storage?.getItem(STORAGE_KEY);
  if (item) {
    const json = JSON.parse(item);
    return json;
  }

  return null;
}
export function saveSearchJson(json: object) {
  if (storage) {
    storage.setItem(STORAGE_KEY, JSON.stringify(json));
  }
}
export function clearStorageByJsonName() {
  if (storage) {
    storage.removeItem(STORAGE_KEY);
  }
}
