import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { ILocalStorage } from '../../legacy/data_sources/local_storages/ILocalStorage';

export default class AuthUserStorage implements ILocalStorage {
  name: string = 'userStore';

  @persist
  @observable
  userId: number | Null = null;

  @persist
  @observable
  email: string | Null = null;

  @action
  clear() {
    this.userId = null;
    this.email = null;
  }
}
