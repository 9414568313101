import ExtendableError from './ExtendableError';

export default class HttpError extends ExtendableError {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }

  get isBadRequest(): boolean {
    return this.statusCode === 400;
  }

  get isNotFound(): boolean {
    return this.statusCode === 404;
  }

  get isConflict(): boolean {
    return this.statusCode === 409;
  }

  get isTooManyRequest(): boolean {
    return this.statusCode === 429;
  }

  get isInternalServerError(): boolean {
    return this.statusCode === 500;
  }

  get isMaintenanceError(): boolean {
    return this.statusCode === 503;
  }
}
