import { configure } from 'mobx';

import { initializeBraze, trackingSetup } from '../models/trackings/Tracking';
import { setHydrateLocalStorages } from '../data_sources/local_storages';
import { switchLanguageByHost, getLocale, isCn } from '../config/locales/i18n';
import Stores from './Stores';
import defaultHydrate from '../shared/utils/defaultHydrate';
import { isServer } from '../shared/utils/next_helper';
import { LocaleType } from '../legacy/types/LocaleType';

configure({
  enforceActions: 'observed',
});

export type BootstrapParams = {
  stores: Stores;
  href: string;
  pathname: string;
};

let storesCache: Stores | Null;

export function initLocale(href: string): LocaleType {
  const host = href.split('/')[2];
  switchLanguageByHost(host);
  return getLocale();
}

export function initStores(locale: LocaleType): Stores {
  if (isServer()) {
    return new Stores({
      locale,
    });
  }

  if (!storesCache) {
    storesCache = new Stores({
      locale,
    });
  }

  return storesCache;
}
export const bootstrap = async ({ stores, href, pathname }: BootstrapParams): Promise<void> => {
  if (isServer()) return;
  const {
    authUserStore,
    couponStore,
    exchangeRateStore,
    searchJsonStore,
    featureArticleStore,
  } = stores;

  trackingSetup(href);

  await setHydrateLocalStorages(defaultHydrate);
  if (!authUserStore.isSignedIn) {
    await authUserStore.signIn();
  }

  if (!isCn()) {
    initializeBraze(authUserStore.userId?.toString());
  }

  couponStore.loadFeaturedCoupon();
  couponStore.loadCouponHistoriesWhenSignedIn();
  exchangeRateStore.loadExchangeRates();
  featureArticleStore.loadFeatureArticleList();

  // 温泉ページではなく、旧yadoページであればJsonをロードする
  if (!pathname.startsWith('/yado/onsen')) {
    searchJsonStore.loadSearchJsons();
  }
};
