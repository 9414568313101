import { observable, computed, action } from 'mobx';
import FilterCondition from '../../core/filter/FilterCondition';
import ExchangeRateStore from './ExchangeRateStore';
import YadoPricesStore from './YadoPricesStore';
import { CurrencyCode } from '../../../legacy/types/ExchangeRates';
import Preference, { PreferenceId } from '../../core/filter/Preference';
import i18n from '../../../R/string/i18n';
import { YadoDetailJson } from '../../../types/response_types/YadoResponseTypes';
import { getDinnerSelectable, hasReservablePrivateBath } from '../../../helpers/yado_helper';
import YadoType, { YadoTypeId } from '../../core/filter/YadoType';
import ReviewScore, { ReviewScoreId } from '../../core/filter/ReviewScore';
import SupportedLanguage, { SupportedLanguageId } from '../../core/filter/SupportedLanguage';
import RoomCount, { RoomCountId } from '../../core/filter/RoomCount';
import FacilityService, { FacilityServiceId } from '../../core/filter/FacilityService';

const PriceRangeMapNew: { [key in CurrencyCode]: number[] } = {
  TWD: [0, 1000, 2000, 5000, 10000, 20000, Infinity],
  HKD: [0, 250, 500, 1000, 2500, 5000, Infinity],
  CNY: [0, 250, 500, 1250, 2500, 5000, Infinity],
  JPY: [0, 5000, 10000, 25000, 50000, 100000, Infinity],
  USD: [0, 30, 70, 150, 300, 600, Infinity],
};

function getPreferences(): Preference[] {
  return [
    new Preference('dinners', i18n.webYadoDinnerSelectable()),
    new Preference('hasTwoMeals', i18n.hasTwoMeals()),
    new Preference('pickups', i18n.webYadoArrangePickup()),
    new Preference('privateBaths', i18n.webYadoPrivateBathReservation()),
  ];
}

function getYadoTypes(): YadoType[] {
  return [
    new YadoType('ryokan', i18n.yadoCategoryRyokan()),
    new YadoType('hotel', i18n.yadoCategoryHotel()),
  ];
}

function getReviewScores(): ReviewScore[] {
  return [
    new ReviewScore(ReviewScoreId('1'), 9, Infinity),
    new ReviewScore(ReviewScoreId('2'), 8, 8.9),
    new ReviewScore(ReviewScoreId('3'), 7, 7.9),
  ];
}

function getSupportedLanguages(): SupportedLanguage[] {
  return [
    new SupportedLanguage('english', i18n.webYadoCommonEnglishLanguage()),
    new SupportedLanguage('mandarin', i18n.webYadoCommonMandarinLanguage()),
    new SupportedLanguage('cantonese', i18n.webYadoCommonCantoneseLanguage()),
  ];
}

function getFacilitiesAndServices(): FacilityService[] {
  return [
    new FacilityService('freeWifi', i18n.webYadoAllRoomsFreeWifi()),
    new FacilityService('frontDesk24', i18n.webYadoFrontDesk24()),
    new FacilityService('concierge', i18n.webYadoConcierge()),
    new FacilityService('cashWithdrawal', i18n.webYadoCashWithdrawal()),
    new FacilityService('moneyExchange', i18n.yadoFacilityMoneyExchange()),
    new FacilityService('roomService24', i18n.webYadoRoomService24()),
    new FacilityService('petFriendly', i18n.webYadoPetFriendly()),
    new FacilityService('selfLaundry', i18n.webYadoSelfLaundry()),
    new FacilityService('laundryService', i18n.webYadoLaundryService()),
    new FacilityService('smokingArea', i18n.webYadoSmokingArea()),
    new FacilityService('indoorSwimmingPool', i18n.webYadoIndoorSwimmingPool()),
    new FacilityService('outdoorSwimmingPool', i18n.yadoFacilityOutdoorPool()),
    new FacilityService('tableTennis', i18n.webYadoTableTennis()),
    new FacilityService('amusementArcade', i18n.webYadoAmusementArcade()),
    new FacilityService('karaoke', i18n.webYadoKaraoke()),
    new FacilityService('fitness', i18n.webYadoFishing()),
    new FacilityService('tennisCourt', i18n.webYadoTennisCourt()),
    new FacilityService('massage', i18n.yadoFacilityMassage()),
    new FacilityService('beautySalon', i18n.yadoFacilityBeautySalon()),
    new FacilityService('giftShop', i18n.webYadoGiftShop()),
    new FacilityService('vendingMachine', i18n.webYadoVendingMachine()),
    new FacilityService('skiStoreroom', i18n.webYadoSkiStoreroom()),
  ];
}

function getRoomCounts(): RoomCount[] {
  return [
    new RoomCount(RoomCountId('1'), 0, 20),
    new RoomCount(RoomCountId('2'), 21, 100),
    new RoomCount(RoomCountId('3'), 101, Infinity),
  ];
}

class FilterConditionStore {
  @observable.ref
  filterCondition: FilterCondition;

  @observable
  shouldShowLoading: boolean = false;

  constructor(
    readonly exchangeRateStore: ExchangeRateStore,
    readonly yadoPriceStore: YadoPricesStore,
  ) {
    this.filterCondition = new FilterCondition([0, Infinity], [], [], [], [], [], [], false, false);
  }

  get currentPriceRange() {
    return this.filterCondition.priceRange;
  }

  @computed
  get preferences(): Preference[] {
    return getPreferences();
  }

  @computed
  get yadoTypes(): YadoType[] {
    return getYadoTypes();
  }

  @computed
  get reviewScores(): ReviewScore[] {
    return getReviewScores();
  }

  @computed
  get supportedLanguages(): SupportedLanguage[] {
    return getSupportedLanguages();
  }

  @computed
  get facilitiesAndServices(): FacilityService[] {
    return getFacilitiesAndServices();
  }

  @computed
  get roomCounts(): RoomCount[] {
    return getRoomCounts();
  }

  @computed
  get validPreferenceIds(): PreferenceId[] {
    return this.filterCondition.preferenceIds.filter(
      preferenceId => this.dividedYadoIdsByPreference.get(preferenceId)?.length || 0 >= 1,
    );
  }

  @computed
  get validYadoTypeIds(): YadoTypeId[] {
    return this.filterCondition.yadoTypeIds.filter(
      yadoTypeId => this.dividedYadoIdsByYadoType.get(yadoTypeId)?.length || 0 >= 1,
    );
  }

  @computed
  get validReviewScoreIds(): ReviewScoreId[] {
    return this.filterCondition.reviewScoreIds.filter(
      reviewScoreId => this.dividedYadoIdsByReviewScore.get(reviewScoreId)?.length || 0 >= 1,
    );
  }

  @computed
  get validSupportedLanguageIds(): SupportedLanguageId[] {
    return this.filterCondition.supportedLanguageIds.filter(
      supportedLanguageId =>
        this.dividedYadoIdsBySupportedLanguage.get(supportedLanguageId)?.length || 0 >= 1,
    );
  }

  @computed
  get validFacilityServiceIds(): FacilityServiceId[] {
    return this.filterCondition.facilityServiceIds.filter(
      facilityServiceId =>
        this.dividedYadoIdsByFacilityService.get(facilityServiceId)?.length || 0 >= 1,
    );
  }

  @computed
  get validRoomCountIds(): RoomCountId[] {
    return this.filterCondition.roomCountIds.filter(
      roomCountId => this.dividedYadoIdsByRoomCount.get(roomCountId)?.length || 0 >= 1,
    );
  }

  @computed
  get validPriceRangeUnlimited(): boolean {
    return this.currentPriceRange[0] === 0 && this.currentPriceRange[1] === Infinity;
  }

  @action
  setPriceRange(priceRange: [number, number]) {
    this.filterCondition = this.filterCondition.deepCopy({ priceRange });
  }

  @action
  setPreferenceIds(preferenceIds: PreferenceId[]) {
    this.filterCondition = this.filterCondition.deepCopy({ preferenceIds });
  }

  @action
  addPreferenceId(preferenceId: PreferenceId) {
    const { preferenceIds } = this.filterCondition;
    if (preferenceIds.find(id => id === preferenceId)) return;
    this.setPreferenceIds([...preferenceIds, preferenceId]);
  }

  @action
  removePreferenceId(preferenceId: PreferenceId) {
    const filteredPreferenceIds = this.filterCondition.preferenceIds.filter(
      id => id !== preferenceId,
    );
    this.setPreferenceIds(filteredPreferenceIds);
  }

  @action
  setYadoTypeIds(yadoTypeIds: YadoTypeId[]) {
    this.filterCondition = this.filterCondition.deepCopy({ yadoTypeIds });
  }

  @action
  addYadoTypeId(yadoTypeId: YadoTypeId) {
    const { yadoTypeIds } = this.filterCondition;
    if (yadoTypeIds.find(id => id === yadoTypeId)) return;
    this.setYadoTypeIds([...yadoTypeIds, yadoTypeId]);
  }

  @action
  removeYadoTypeId(yadoTypeId: YadoTypeId) {
    const filteredYadoTypeIds = this.filterCondition.yadoTypeIds.filter(id => id !== yadoTypeId);
    this.setYadoTypeIds(filteredYadoTypeIds);
  }

  @action
  setReviewScoreIds(reviewScoreIds: ReviewScoreId[]) {
    this.filterCondition = this.filterCondition.deepCopy({ reviewScoreIds });
  }

  @action
  addReviewScoreId(reviewScoreId: ReviewScoreId) {
    const { reviewScoreIds } = this.filterCondition;
    if (reviewScoreIds.find(id => id === reviewScoreId)) return;
    this.setReviewScoreIds([...reviewScoreIds, reviewScoreId]);
  }

  @action
  removeReviewScoreId(reviewScoreId: ReviewScoreId) {
    const filteredReviewScoreIds = this.filterCondition.reviewScoreIds.filter(
      id => id !== reviewScoreId,
    );
    this.setReviewScoreIds(filteredReviewScoreIds);
  }

  @action
  setSupportedLanguageIds(supportedLanguageIds: SupportedLanguageId[]) {
    this.filterCondition = this.filterCondition.deepCopy({ supportedLanguageIds });
  }

  @action
  addSupportedLanguageId(supportedLanguageId: SupportedLanguageId) {
    const { supportedLanguageIds } = this.filterCondition;
    if (supportedLanguageIds.find(id => id === supportedLanguageId)) return;
    this.setSupportedLanguageIds([...supportedLanguageIds, supportedLanguageId]);
  }

  @action
  removeSupportedLanguageId(supportedLanguageId: SupportedLanguageId) {
    const filteredSupportedLanguageIds = this.filterCondition.supportedLanguageIds.filter(
      id => id !== supportedLanguageId,
    );
    this.setSupportedLanguageIds(filteredSupportedLanguageIds);
  }

  @action
  setFacilityServiceIds(facilityServiceIds: FacilityServiceId[]) {
    this.filterCondition = this.filterCondition.deepCopy({ facilityServiceIds });
  }

  @action
  addFacilityServiceId(facilityServiceId: FacilityServiceId) {
    const { facilityServiceIds } = this.filterCondition;
    if (facilityServiceIds.find(id => id === facilityServiceId)) return;
    this.setFacilityServiceIds([...facilityServiceIds, facilityServiceId]);
  }

  @action
  removeFacilityServiceId(facilityServiceId: FacilityServiceId) {
    const filteredFacilityServiceIds = this.filterCondition.facilityServiceIds.filter(
      id => id !== facilityServiceId,
    );
    this.setFacilityServiceIds(filteredFacilityServiceIds);
  }

  @action
  setRoomCountIds(roomCountIds: RoomCountId[]) {
    this.filterCondition = this.filterCondition.deepCopy({ roomCountIds });
  }

  @action
  addRoomCountId(roomCountId: RoomCountId) {
    const { roomCountIds } = this.filterCondition;
    if (roomCountIds.find(id => id === roomCountId)) return;
    this.setRoomCountIds([...roomCountIds, roomCountId]);
  }

  @action
  removeRoomCountId(roomCountId: RoomCountId) {
    const filteredRoomCountIds = this.filterCondition.roomCountIds.filter(id => id !== roomCountId);
    this.setRoomCountIds(filteredRoomCountIds);
  }

  @action
  clearAllFilter() {
    this.setPriceRange([0, Infinity]);
    this.setPreferenceIds([]);
    this.setYadoTypeIds([]);
    this.setReviewScoreIds([]);
    this.setSupportedLanguageIds([]);
    this.setFacilityServiceIds([]);
    this.setRoomCountIds([]);
    this.filterCondition = this.filterCondition.deepCopy({
      hasAvailableRooms: false,
      hasExclusivePlan: false,
    });
  }

  @action
  handleFilterConditionByUrl(hashQuery: {
    priceRange?: [number, number];
    preferenceIds?: PreferenceId[];
    yadoTypeIds?: YadoTypeId[];
    reviewScoreIds?: ReviewScoreId[];
    supportedLanguageIds?: SupportedLanguageId[];
    facilityServiceIds?: FacilityServiceId[];
    roomCountIds?: RoomCountId[];
  }) {
    const {
      priceRange,
      preferenceIds,
      yadoTypeIds,
      reviewScoreIds,
      supportedLanguageIds,
      facilityServiceIds,
      roomCountIds,
    } = hashQuery;
    if (
      !priceRange &&
      !preferenceIds &&
      !yadoTypeIds &&
      !reviewScoreIds &&
      !supportedLanguageIds &&
      !facilityServiceIds &&
      !roomCountIds
    )
      return;
    if (priceRange) this.setPriceRange(priceRange as [number, number]);
    if (preferenceIds) this.setPreferenceIds(preferenceIds as PreferenceId[]);
    if (yadoTypeIds) this.setYadoTypeIds(yadoTypeIds as YadoTypeId[]);
    if (reviewScoreIds) this.setReviewScoreIds(reviewScoreIds as ReviewScoreId[]);
    if (supportedLanguageIds)
      this.setSupportedLanguageIds(supportedLanguageIds as SupportedLanguageId[]);
    if (facilityServiceIds) this.setFacilityServiceIds(facilityServiceIds as FacilityServiceId[]);
    if (roomCountIds) this.setRoomCountIds(roomCountIds as RoomCountId[]);
  }

  @computed
  get filteredYadoIds(): string[] | Null {
    if (!this.exchangeRateStore.loaded || !this.yadoPriceStore.isAllLoaded) return null;

    const { yadoIds } = this.yadoPriceStore;
    const {
      validPriceRangeUnlimited,
      validPriceRange,
      validPreferenceIds,
      validYadoTypeIds,
      validReviewScoreIds,
      validSupportedLanguageIds,
      validFacilityServiceIds,
      validRoomCountIds,
    } = this;
    const filterHasAvailableRooms = this.filterCondition.hasAvailableRooms;
    const filterHasExclusivePlan = this.filterCondition.hasExclusivePlan;

    const filteredYadoIds = yadoIds.filter(
      yadoId =>
        (validPriceRangeUnlimited || validPriceRange(yadoId)) &&
        (validPreferenceIds.length === 0 ||
          validPreferenceIds.some(preferenceId =>
            this.dividedYadoIdsByPreference.get(preferenceId)?.includes(yadoId),
          )) &&
        (validYadoTypeIds.length === 0 ||
          validYadoTypeIds.some(yadoTypeId =>
            this.dividedYadoIdsByYadoType.get(yadoTypeId)?.includes(yadoId),
          )) &&
        (validReviewScoreIds.length === 0 ||
          validReviewScoreIds.some(reviewScoreId =>
            this.dividedYadoIdsByReviewScore.get(reviewScoreId)?.includes(yadoId),
          )) &&
        (validSupportedLanguageIds.length === 0 ||
          validSupportedLanguageIds.some(supportedLanguageId =>
            this.dividedYadoIdsBySupportedLanguage.get(supportedLanguageId)?.includes(yadoId),
          )) &&
        (validFacilityServiceIds.length === 0 ||
          validFacilityServiceIds.some(facilityServiceId =>
            this.dividedYadoIdsByFacilityService.get(facilityServiceId)?.includes(yadoId),
          )) &&
        (validRoomCountIds.length === 0 ||
          validRoomCountIds.some(roomCountId =>
            this.dividedYadoIdsByRoomCount.get(roomCountId)?.includes(yadoId),
          )) &&
        (!filterHasAvailableRooms ||
          (filterHasAvailableRooms && this.dividedYadoIdsByHasAvailableRooms.includes(yadoId))) &&
        (!filterHasExclusivePlan ||
          (filterHasExclusivePlan && this.dividedYadoIdsByHasExclusivePlan.includes(yadoId))),
    );

    return filteredYadoIds;
  }

  @computed
  get currentPriceRangeList(): number[] {
    const currency = this.exchangeRateStore.userExchangeRate?.currency;

    if (!currency) return [];
    return PriceRangeMapNew[currency] || [];
  }

  @computed
  get validPriceRange(): (id: string) => boolean {
    const [lower, upper] = this.filterCondition.priceRange.sort((a, b) => a - b);
    const jpyLower = this.exchangeRateStore.userExchangeRate?.reverseExchange(lower);
    const jpyUpper = this.exchangeRateStore.userExchangeRate?.reverseExchange(upper);

    const filter = (yadoID: string): boolean => {
      if (!jpyUpper || (!jpyLower && jpyLower !== 0)) {
        return false;
      }

      const yadoPrice = this.yadoPriceStore.findYadoPrice(yadoID);
      const price = yadoPrice?.discountedPriceIncludingTax || yadoPrice?.priceIncludingTax;
      if (!price) return false;
      return price >= jpyLower && price <= jpyUpper;
    };

    return filter;
  }

  @computed
  get dividedYadoIdsByHasExclusivePlan(): string[] {
    return this.yadoPriceStore.yadoIds.filter(yadoId => {
      const yadoDetailJson = this.yadoPriceStore.findYadoPrice(yadoId)?.yadoDetailJson;
      return !!(yadoDetailJson && yadoDetailJson.hasWamazingExclusivePlan);
    });
  }

  @computed
  get dividedYadoIdsByHasAvailableRooms(): string[] {
    return this.yadoPriceStore.yadoIds.filter(yadoID => {
      const yadoPrice = this.yadoPriceStore.findYadoPrice(yadoID);
      return yadoPrice?.discountedPriceIncludingTax || yadoPrice?.priceIncludingTax;
    });
  }

  @computed
  get dividedYadoIdsByPreference(): Map<PreferenceId, string[]> {
    const { yadoIds } = this.yadoPriceStore;
    return this.preferences.reduce((acc, preference) => {
      const matchedYadoIds = yadoIds.filter(yadoId => {
        const yadoPrice = this.yadoPriceStore.findYadoPrice(yadoId);
        const yadoDetailJson = yadoPrice?.yadoDetailJson;
        if (!yadoDetailJson) return false;
        return this.matchWithPreference(yadoDetailJson, preference.preferenceId, {
          hasTwoMeals: yadoPrice.hasTwoMeals,
        });
      });
      return acc.set(preference.preferenceId, matchedYadoIds);
    }, new Map());
  }

  @computed
  get dividedYadoIdsByYadoType(): Map<YadoTypeId, string[]> {
    const { yadoIds } = this.yadoPriceStore;
    return this.yadoTypes.reduce((acc, yadoType) => {
      const matchedYadoIds = yadoIds.filter(yadoId => {
        const yadoDetailJson = this.yadoPriceStore.findYadoPrice(yadoId)?.yadoDetailJson;
        if (!yadoDetailJson) return false;
        return this.matchWithYadoType(yadoDetailJson, yadoType.yadoTypeId);
      });
      return acc.set(yadoType.yadoTypeId, matchedYadoIds);
    }, new Map());
  }

  @computed
  get dividedYadoIdsByReviewScore(): Map<ReviewScoreId, string[]> {
    const { yadoIds } = this.yadoPriceStore;
    return this.reviewScores.reduce((acc, reviewScore) => {
      const matchedYadoIds = yadoIds.filter(yadoId => {
        const yadoDetailJson = this.yadoPriceStore.findYadoPrice(yadoId)?.yadoDetailJson;
        if (!yadoDetailJson || !yadoDetailJson.rating.total) return false;
        return (
          parseInt(yadoDetailJson.rating.total, 10) >= reviewScore.low &&
          parseInt(yadoDetailJson.rating.total, 10) < reviewScore.high
        );
      });
      return acc.set(reviewScore.reviewScoreId, matchedYadoIds);
    }, new Map());
  }

  @computed
  get dividedYadoIdsBySupportedLanguage(): Map<SupportedLanguageId, string[]> {
    const { yadoIds } = this.yadoPriceStore;
    return this.supportedLanguages.reduce((acc, supportedLanguage) => {
      const matchedYadoIds = yadoIds.filter(yadoId => {
        const yadoDetailJson = this.yadoPriceStore.findYadoPrice(yadoId)?.yadoDetailJson;
        if (!yadoDetailJson) return false;
        return this.matchWithSupportedLanguage(
          yadoDetailJson,
          supportedLanguage.supportedLanguageId,
        );
      });
      return acc.set(supportedLanguage.supportedLanguageId, matchedYadoIds);
    }, new Map());
  }

  @computed
  get dividedYadoIdsByFacilityService(): Map<FacilityServiceId, string[]> {
    const { yadoIds } = this.yadoPriceStore;
    return this.facilitiesAndServices.reduce((acc, facilityService) => {
      const matchedYadoIds = yadoIds.filter(yadoId => {
        const yadoDetailJson = this.yadoPriceStore.findYadoPrice(yadoId)?.yadoDetailJson;
        if (!yadoDetailJson) return false;
        return this.matchWithFacilityService(
          yadoDetailJson,
          facilityService.facilityServiceId as keyof typeof yadoDetailJson[
            | 'service'
            | 'roomFacility'
            | 'amusement'
            | 'mealAndDrink'],
        );
      });
      return acc.set(facilityService.facilityServiceId, matchedYadoIds);
    }, new Map());
  }

  @computed
  get dividedYadoIdsByRoomCount(): Map<RoomCountId, string[]> {
    const { yadoIds } = this.yadoPriceStore;
    return this.roomCounts.reduce((acc, roomCount) => {
      const matchedYadoIds = yadoIds.filter(yadoId => {
        const yadoDetailJson = this.yadoPriceStore.findYadoPrice(yadoId)?.yadoDetailJson;
        if (!yadoDetailJson || !yadoDetailJson.numberOfRooms) return false;
        return (
          yadoDetailJson.numberOfRooms >= roomCount.low &&
          yadoDetailJson.numberOfRooms < roomCount.high
        );
      });
      return acc.set(roomCount.roomCountId, matchedYadoIds);
    }, new Map());
  }

  @action
  showLoadingForMilliseconds(duration: number = 500) {
    this.shouldShowLoading = true;
    setTimeout(
      action(() => {
        this.shouldShowLoading = false;
      }),
      duration,
    );
  }

  @action
  shouldFilterYadosByHasExclusivePlan(hasExclusivePlanCondition: boolean) {
    this.filterCondition = this.filterCondition.deepCopy({
      hasExclusivePlan: hasExclusivePlanCondition,
    });
  }

  @action
  shouldFilterYadosByHasAvailableRooms(hasAvailableRoomsCondition: boolean) {
    this.filterCondition = this.filterCondition.deepCopy({
      hasAvailableRooms: hasAvailableRoomsCondition,
    });
  }

  findPreference(preferenceId: PreferenceId): Preference | Null {
    return this.preferences.find(p => p.preferenceId === preferenceId);
  }

  findYadoType(yadoTypeId: YadoTypeId): YadoType | Null {
    return this.yadoTypes.find(p => p.yadoTypeId === yadoTypeId);
  }

  findReviewScore(reviewScoreId: ReviewScoreId): ReviewScore | Null {
    return this.reviewScores.find(p => p.reviewScoreId === reviewScoreId);
  }

  findSupportedLanguage(supportedLanguageId: SupportedLanguageId): SupportedLanguage | Null {
    return this.supportedLanguages.find(p => p.supportedLanguageId === supportedLanguageId);
  }

  findFacilityService(facilityServiceId: FacilityServiceId): FacilityService | Null {
    return this.facilitiesAndServices.find(p => p.facilityServiceId === facilityServiceId);
  }

  findRoomCount(roomCountId: RoomCountId): RoomCount | Null {
    return this.roomCounts.find(p => p.roomCountId === roomCountId);
  }

  private matchWithPreference(
    yadoDetailJson: YadoDetailJson,
    preferenceId: PreferenceId,
    extra?: { hasTwoMeals: boolean },
  ): boolean {
    switch (preferenceId) {
      case 'dinners':
        return getDinnerSelectable(yadoDetailJson);
      case 'pickups':
        return yadoDetailJson.pickups.length >= 1;
      case 'privateBaths':
        return hasReservablePrivateBath(yadoDetailJson.privateBaths);
      case 'hasTwoMeals':
        return !!extra?.hasTwoMeals;
      default:
        return false;
    }
  }

  private matchWithYadoType(yadoDetailJson: YadoDetailJson, yadoTypeId: YadoTypeId): boolean {
    return yadoTypeId === yadoDetailJson.category;
  }

  private matchWithSupportedLanguage(
    yadoDetailJson: YadoDetailJson,
    supportedLanguageId: SupportedLanguageId,
  ): boolean {
    if (yadoDetailJson.language[supportedLanguageId] === true) {
      return true;
    }
    return false;
  }

  private matchWithFacilityService(
    yadoDetailJson: YadoDetailJson,
    facilityServiceId: keyof typeof yadoDetailJson[
      | 'service'
      | 'roomFacility'
      | 'amusement'
      | 'mealAndDrink'],
  ): boolean {
    const { service, roomFacility, amusement, mealAndDrink } = yadoDetailJson;
    if (
      service[facilityServiceId] ||
      roomFacility[facilityServiceId] ||
      amusement[facilityServiceId] ||
      mealAndDrink[facilityServiceId]
    ) {
      return true;
    }
    return false;
  }
}

export default FilterConditionStore;
