export interface RoomCountId extends String {
  _roomCountIdBrand: string; // ref: https://typescript-jp.gitbook.io/deep-dive/main-1/nominaltyping#intfsuno
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export function RoomCountId(id: string): RoomCountId {
  return id as any;
}

class RoomCount {
  constructor(readonly roomCountId: RoomCountId, readonly low: number, readonly high: number) {}
}

export default RoomCount;
