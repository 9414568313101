import { WAProduct } from "./productType";

export const convertToUtmParams = (name: WAProduct | undefined) => {
  switch (name) {
    case "yado":
      return "wa-yado";
    case "ticket":
      return "wa-ticket";
    case "activity":
      return "wa-activity";
    case "snow":
      return "wa-snow";
    case "discover":
      return "wa-media";
    case "kaimono":
      return "wa-kaimono";
    case "insurance":
      return "wa-insurance";
    case "wamazing":
      return "wa-account";

    default:
      return "";
  }
};

export const serviceLink = (
  link: string,
  name: WAProduct | undefined,
  campaign: "header" | "footer" | "header_menu"
) => {
  return `${link}?utm_source=${convertToUtmParams(
    name
  )}&utm_medium=referral&utm_campaign=${campaign}`;
};
