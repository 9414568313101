/* eslint-disable max-classes-per-file */
import { observable, computed, runInAction, when } from 'mobx';

import AuthUserStore from './AuthUserStore';

import { CouponHistory, Coupon } from '../../../types/response_types/CouponResponseTypes';
import {
  fetchCouponHistories,
  fetchFeaturedCoupons,
} from '../../../data_sources/networks/yado-backend/CouponApi';
import { isServer } from '../../../shared/utils/next_helper';

function canApplyCoupon(
  coupon: Coupon,
  priceJpy: number,
  yadoId: string,
  areaSlug: string | Null,
  checkinDate: string | Null,
): boolean {
  return (
    canApplyCouponToYado(coupon, yadoId) &&
    canApplyCouponToArea(coupon, areaSlug) &&
    isWithinAvailableCheckinDates(coupon, checkinDate) &&
    isOverMinSettelentAmmount(priceJpy, coupon) &&
    !coupon.consumeLimitReached
  );
}

function canApplyCouponToYado(coupon: Coupon, yadoId: string): boolean {
  return coupon.yadoIds == null || coupon.yadoIds.includes(yadoId);
}

function canApplyCouponToArea(coupon: Coupon, areaSlug: string | Null): boolean {
  if (coupon.areaSlugs == null) return true;
  return areaSlug != null && coupon.areaSlugs.includes(areaSlug);
}

function isWithinAvailableCheckinDates(coupon: Coupon, checkinDate: string | Null): boolean {
  // クーポンにチェックイン日の指定がなければtrue
  if (!coupon.checkinDate) return true;
  // クーポンにチェックイン日の指定があるが、引数のcheckinDateがnullならfalse
  if (!checkinDate) return false;
  // クーポンで指定されているチェックイン日の中の場合にtrue
  return (
    new Date(coupon.checkinDate.from) <= new Date(checkinDate) &&
    new Date(coupon.checkinDate.to) >= new Date(checkinDate)
  );
}

function isOverMinSettelentAmmount(priceJpy: number, coupon: Coupon): boolean {
  return coupon.minSettlementAmount == null || coupon.minSettlementAmount <= priceJpy;
}

export function calculateDiscountAmountWithCoupon(
  priceJpy: number | Null,
  coupon: Coupon | Null,
  yadoId: string | Null,
  areaSlug: string | Null,
  checkinDate: string | Null,
): number | Null {
  // priceJpyは0になる可能性があるので== nullで判定
  if (priceJpy == null) return null;
  if (coupon == null) return null;
  if (yadoId == null) return null;
  if (!canApplyCoupon(coupon, priceJpy, yadoId, areaSlug, checkinDate)) return null;

  if (typeof coupon.discountAmount === 'number') {
    return Math.min(priceJpy, coupon.discountAmount);
  }

  if (typeof coupon.discountRate === 'number') {
    return Math.floor(priceJpy * coupon.discountRate);
  }

  return null;
}

function getDiscountAmountsWithCoupons(
  priceJpy: number,
  coupons: Coupon[],
  yadoId: string,
  areaSlug: string | Null,
  checkinDate: string | Null,
): number[] {
  const discountAmounts = coupons
    .map(c => calculateDiscountAmountWithCoupon(priceJpy, c, yadoId, areaSlug, checkinDate))
    .filter(a => a != null) as number[];
  return discountAmounts;
}

export function getHighestDiscountAmountWithCoupons(
  priceJpy: number | Null,
  coupons: Coupon[],
  yadoId: string,
  areaSlug: string | Null,
  checkinDate: string | Null,
): number | Null {
  if (priceJpy == null) return null;
  const discountAmounts = getDiscountAmountsWithCoupons(
    priceJpy,
    coupons,
    yadoId,
    areaSlug,
    checkinDate,
  );
  if (discountAmounts.length === 0) return null;
  const sortedDiscountAmounts = discountAmounts.sort((aA, aB) => aB - aA);
  return sortedDiscountAmounts[0];
}
export function getLowestDiscountedPriceWithCoupons(
  priceJpy: number | Null,
  coupons: Coupon[],
  yadoId: string,
  areaSlug: string | Null,
  checkinDate: string | Null,
): number | Null {
  if (priceJpy == null) return null;
  const highestDiscountAmount = getHighestDiscountAmountWithCoupons(
    priceJpy,
    coupons,
    yadoId,
    areaSlug,
    checkinDate,
  );
  if (!highestDiscountAmount) return null;
  return priceJpy - highestDiscountAmount;
}

export type FeaturedCouponShowPatternType =
  | 'not_show'
  | 'show_when_signed_in'
  | 'show_when_not_signed_in';
export const FeaturedCouponShowPattern: {
  [key: string]: FeaturedCouponShowPatternType;
} = {
  NOT_SHOW: 'not_show',
  SHOW_WHEN_SIGNED_IN: 'show_when_signed_in',
  SHOW_WHEN_NOT_SIGNED_IN: 'show_when_not_signed_in',
};
export class FeaturedCoupon {
  couponObj: Coupon;

  constructor(couponObj: Coupon) {
    this.couponObj = couponObj;
  }

  get featuredPeriodStart(): Date | Null {
    if (this.couponObj.featuredPeriod) {
      const { featuredPeriod } = this.couponObj;
      return new Date(featuredPeriod.from);
    }

    return null;
  }

  get featuredPeriodEnd(): Date | Null {
    if (this.couponObj.featuredPeriod) {
      const { featuredPeriod } = this.couponObj;
      return new Date(featuredPeriod.to);
    }

    return null;
  }
}

export default class CouponStore {
  authUserStore: AuthUserStore;

  @observable
  private rawCouponHistories: CouponHistory[] = [];

  @observable
  featuredCoupons: FeaturedCoupon[] = [];

  constructor(authUserStore: AuthUserStore) {
    this.authUserStore = authUserStore;
  }

  loadCouponHistoriesWhenSignedIn() {
    when(
      () => this.authUserStore.isSignedIn,
      () => {
        this.loadCouponHistories();
      },
    );
  }

  async loadCouponHistories() {
    const couponHistories = await fetchCouponHistories().catch(() => []);
    runInAction(() => {
      this.rawCouponHistories = couponHistories;
    });
  }

  async loadFeaturedCoupon() {
    const featuredCouponObjects = await fetchFeaturedCoupons().catch((): Coupon[] => []);
    runInAction(() => {
      this.featuredCoupons = featuredCouponObjects.map(fco => new FeaturedCoupon(fco));
    });
  }

  @computed
  get featuredCoupon(): FeaturedCoupon | Null {
    if (this.featuredCoupons.length >= 1) {
      return this.featuredCoupons[this.featuredCoupons.length - 1];
    }

    return null;
  }

  @computed
  get isLoadedCouponHistories(): boolean {
    return this.authUserStore.isSignedIn && this.rawCouponHistories.length >= 1;
  }

  @computed
  get unconsumedCouponHistories(): CouponHistory[] {
    return this.rawCouponHistories.filter((ch: CouponHistory) => ch.status === 'unconsumed');
  }

  @computed
  get unconsumedCouponHistoryOfFeaturedCoupon(): CouponHistory | Null {
    if (!this.featuredCoupon) return null;
    const featuredCouponId = this.featuredCoupon.couponObj.couponId;
    return this.unconsumedCouponHistories.find(ch => ch.coupon.couponId === featuredCouponId);
  }

  @computed
  get featuredCouponShowPattern(): FeaturedCouponShowPatternType {
    const { NOT_SHOW, SHOW_WHEN_SIGNED_IN, SHOW_WHEN_NOT_SIGNED_IN } = FeaturedCouponShowPattern;
    if (isServer()) return NOT_SHOW; // SSR時は非表示
    if (!this.featuredCoupon) return NOT_SHOW;
    if (this.authUserStore.isLoading) return NOT_SHOW;

    if (this.unconsumedCouponHistoryOfFeaturedCoupon) {
      return SHOW_WHEN_SIGNED_IN;
    }

    if (!this.authUserStore.isSignedIn) {
      return SHOW_WHEN_NOT_SIGNED_IN;
    }

    return NOT_SHOW;
  }

  @computed
  get featuredCouponCountDownEndDate(): Date | Null {
    if (!this.featuredCoupon) return null;

    if (
      this.unconsumedCouponHistoryOfFeaturedCoupon &&
      this.unconsumedCouponHistoryOfFeaturedCoupon.expireAt
    ) {
      return new Date(this.unconsumedCouponHistoryOfFeaturedCoupon.expireAt);
    }

    if (this.featuredCoupon.featuredPeriodEnd) {
      return this.featuredCoupon.featuredPeriodEnd;
    }

    return null;
  }

  @computed
  get canShowFeaturedCoupon(): boolean {
    const { SHOW_WHEN_NOT_SIGNED_IN, SHOW_WHEN_SIGNED_IN } = FeaturedCouponShowPattern;
    return (
      this.featuredCouponShowPattern === SHOW_WHEN_NOT_SIGNED_IN ||
      this.featuredCouponShowPattern === SHOW_WHEN_SIGNED_IN
    );
  }

  @computed
  get coupons(): Coupon[] {
    if (this.isLoadedCouponHistories) {
      return this.unconsumedCouponHistories.map(ch => ch.coupon);
    }

    if (this.featuredCoupon && this.canShowFeaturedCoupon) {
      return [this.featuredCoupon.couponObj];
    }

    return [];
  }

  findCouponHavingHighestDiscountAmount(
    priceJpy: number,
    yadoId: string,
    areaSlug: string | Null,
    checkinDate: string | Null,
  ): Coupon | Null {
    return this.coupons
      .filter(c => canApplyCoupon(c, priceJpy, yadoId, areaSlug, checkinDate))
      .sort((cA, cB) => {
        const amountA = calculateDiscountAmountWithCoupon(
          priceJpy,
          cA,
          yadoId,
          areaSlug,
          checkinDate,
        );
        const amountB = calculateDiscountAmountWithCoupon(
          priceJpy,
          cB,
          yadoId,
          areaSlug,
          checkinDate,
        );
        return (amountB ?? 0) - (amountA ?? 0);
      })[0];
  }

  findCouponHistoryHavingHighestDiscountAmount(
    priceJpy: number | Null,
    yadoId: string,
    areaSlug: string | Null,
    checkinDate: string | Null,
  ): CouponHistory | Null {
    if (priceJpy == null) return null;
    const couponHavingHighestDiscountAmount = this.findCouponHavingHighestDiscountAmount(
      priceJpy,
      yadoId,
      areaSlug,
      checkinDate,
    );
    if (couponHavingHighestDiscountAmount == null) return null;
    return this.unconsumedCouponHistories.find(
      ({ coupon }) => coupon.couponId === couponHavingHighestDiscountAmount.couponId,
    );
  }

  findAvailableCouponHistories(
    priceJpy: number | Null,
    yadoId: string,
    areaSlug: string | Null,
    checkinDate: string | Null,
  ): CouponHistory[] {
    if (priceJpy == null) return [];
    return this.unconsumedCouponHistories.filter(({ coupon }) =>
      canApplyCoupon(coupon, priceJpy, yadoId, areaSlug, checkinDate),
    );
  }

  findUnconsumedCouponHistory(couponHistoryId: (string | number) | Null): CouponHistory | Null {
    if (couponHistoryId == null) return null;

    const couponHistory = this.unconsumedCouponHistories.find(
      ch => String(ch.couponHistoryId) === String(couponHistoryId),
    );
    return couponHistory;
  }
}
