import * as React from 'react';
import { Colors } from '../shared/styles/StyleConst';

export function markdownLinkToAHref(str: string): string {
  const markdownLinkRegex = /\[(.+?)\]\((https?:\/\/.+?)\)/g;
  return str.replace(
    markdownLinkRegex,
    '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>',
  );
}

export function scrollToElemOfId(id: string, behavior: ScrollBehavior) {
  const elem = document.getElementById(id);
  if (elem == null) return;
  const offsetTop = elem.getBoundingClientRect().top + window.scrollY;
  window.scrollTo({
    top: offsetTop,
    left: 0,
    behavior,
  });
}

function partiallyRegex(wordOrRegexToStyle: string, text: string): [string[], RegExp | Null] {
  const regex = new RegExp(wordOrRegexToStyle, 'g');
  const matches = text.match(regex) || [];
  if (matches.length === 0) {
    return [matches, null];
  }
  // 複数の区切り文字でsplitできるように正規表現(hoge|fuga)を作る
  // 丸括弧を使うことでsplitしてもseparatorを残す: https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/String/split#Capturing_parentheses
  const regexForSplit = new RegExp(`(${matches.join('|')})`, 'g');
  return [matches, regexForSplit];
}

function styleTextPartially(
  wordOrRegexToStyle: string,
  text: string,
  styles: React.CSSProperties,
): React.ReactNode {
  const [matches, regexForSplit] = partiallyRegex(wordOrRegexToStyle, text);
  if (regexForSplit == null) return text;
  const styled = text.split(regexForSplit).map((str, index) => {
    const shouldStyle = matches.includes(str);
    if (str === '') return null;
    return (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        {shouldStyle ? <span style={styles}>{str}</span> : str}
      </React.Fragment>
    );
  });
  return styled;
}

export function highlightTextPartially(
  wordOrRegexToHighlight: string,
  text: string,
  color: string = Colors.pink,
  extraStyle: object = {},
): React.ReactNode {
  return styleTextPartially(wordOrRegexToHighlight, text, {
    color,
    fontWeight: 'bold',
    fontSize: '120%',
    ...extraStyle,
  });
}

export function boldTextPartially(
  wordOrRegexToBold: string,
  text: string,
  fontWeight: number | 'bold' = 'bold',
  extraStyle: object = {},
): React.ReactNode {
  return styleTextPartially(wordOrRegexToBold, text, { fontWeight, ...extraStyle });
}
