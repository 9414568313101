import { observable, computed, action } from 'mobx';
import { fetchExchangeRates } from '../../../legacy/data_sources/networks/wamazing/ExchangeRateApi';
import { LocaleType } from '../../../legacy/types/LocaleType';
import { CurrencyCode } from '../../../legacy/types/ExchangeRates';
import { isJp } from '../../../config/locales/i18n';
import ExchangeRate from '../../core/ExchangeRate';
import SentryReporter from '../../trackings/SentryReporter';

const LOCALE_CURRENCY_MAP: {
  [key: number]: CurrencyCode;
  [key: string]: CurrencyCode;
} = {
  zh_tw: 'TWD',
  zh_hk: 'HKD',
  zh_cn: 'CNY',
  en: 'USD',
  ja: 'JPY',
};

export function getDefaultCurrencyFromLocale(locale: LocaleType): CurrencyCode {
  return LOCALE_CURRENCY_MAP[locale];
}

export default class ExchangeRateStore {
  constructor(readonly locale: LocaleType) {}

  @observable
  exchangeRates: ExchangeRate[] | Null;

  @observable
  userExchangeRate: ExchangeRate | Null;

  @observable
  loading: boolean = false;

  @observable
  loaded: boolean = false;

  async loadExchangeRates(): Promise<void> {
    if (isJp() && this.canLoad) {
      this.setUserExchangeRate(new ExchangeRate(1, 'JPY'));
      this.setLoaded(true);
      return;
    }
    if (this.canLoad) {
      this.setLoading(true);
      this.exchangeRates = await fetchExchangeRates()
        .then(ratesResponse => {
          return ratesResponse.map(rate => new ExchangeRate(rate.exchangeRate, rate.toCurrency));
        })
        .catch(error => {
          SentryReporter.captureException(`Failed to fetch exchangeRate ${error.message}`);
          return null;
        });

      if (this.exchangeRates) {
        const userCurrency = getDefaultCurrencyFromLocale(this.locale);
        this.setUserExchangeRate(
          this.exchangeRates.find(exchangeRate => exchangeRate.currency === userCurrency),
        );
        this.setLoading(false);
        this.setLoaded(true);
        return;
      }
      this.setLoading(false);
    }
  }

  async reloadExchangeRates(): Promise<void> {
    this.setLoaded(false);
    await this.loadExchangeRates();
  }

  @computed
  get canLoad(): boolean {
    return !this.loaded && !this.loading;
  }

  @action
  setUserExchangeRate(exchangeRate: ExchangeRate | Null) {
    this.userExchangeRate = exchangeRate || null;
  }

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  setLoaded(loaded: boolean) {
    this.loaded = loaded;
  }
}
