import { observable, computed, runInAction, action } from 'mobx';
import { fetchSearchJsons } from '../../../data_sources/networks/SearchJsonApi';
import {
  saveSearchJson,
  getSearchJson,
} from '../../../data_sources/session_storages/SearchJsonSessionStorage';
import { Prefectures } from '../../../legacy/models/resources/PrefectureType';

export type Cities = {
  [key: string]: {
    [key: string]: {
      zh_tw: string;
      ja: string;
      zh_cn: string;
      en: string;
      hotel_count: number;
      for_suggestion: boolean;
    };
  };
};

export type Spots = {
  [key: string]: {
    zh_tw: string;
    en: string;
    zh_cn: string;
    prefecture: string;
    city: string;
    hotel_count: number;
    for_suggestion: boolean;
  };
};

export type Features = {
  [key: string]: {
    zh_tw: string;
    en: string;
    zh_cn: string;
    hotel_count: number;
    for_suggestion: boolean;
  };
};

export type FeatureRelations = {
  [key: string]: {
    [key: string]: {
      prefecture: string;
      hotel_count: number;
    };
  };
};

export default class SearchJsonStore {
  @observable.ref
  prefectures: Prefectures | Null;

  @observable.ref
  cities: Cities | Null;

  @observable.ref
  spots: Spots | Null;

  @observable.ref
  features: Features | Null;

  @observable.ref
  citySpots: object | Null;

  @observable.ref
  featureRelations: FeatureRelations | Null;

  @observable.ref
  prefectureDetails: object | Null;

  @observable
  loading: boolean = false;

  @observable
  loaded: boolean = false;

  async loadSearchJsons(): Promise<boolean | Null> {
    if (this.canLoad) {
      this.setLoading(true);
      const jsons = getSearchJson() || (await fetchSearchJsons());

      if (jsons) {
        runInAction(() => {
          this.prefectures = jsons.prefectures;
          this.cities = jsons.cities;
          this.spots = jsons.spots;
          this.features = jsons.features;
          this.citySpots = jsons.citySpots;
          this.featureRelations = jsons.featureRelations;
          this.prefectureDetails = jsons.prefectureDetails;
        });
        saveSearchJson(jsons);
        this.setLoading(false);
        this.setLoaded(true);

        return true;
      }

      this.setLoading(false);

      return false;
    }

    return false;
  }

  async reloadSearchJsons(): Promise<boolean | Null> {
    this.setLoaded(false);
    return this.loadSearchJsons();
  }

  @computed
  get canLoad(): boolean {
    return !this.loaded && !this.loading;
  }

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @action
  setLoaded(loaded: boolean) {
    this.loaded = loaded;
  }
}
