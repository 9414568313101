import { when } from 'mobx';

import { pageEvents } from '../../models/trackings/trackingEvents';
import { trackEvent, trackPixelSearch } from '../../models/trackings/Tracking';
import AuthUserStore from '../../models/mobx/stores/AuthUserStore';
import EsYadoSearch from '../../legacy/models/networks/yado-backend/EsYadoSearch';
import { LinkProps } from '../../shared/utils/url_helper';
import { RelatedFeaturesType } from '../../legacy/models/resources/Features';
import { Features } from '../../models/mobx/stores/SearchJsonStore';
import { createYadoListLink } from '../../legacy/shared/utils/urlUtil';
import { OldSearchCondition } from '../../legacy/shared/utils/searchUtil';
import {
  FeatureArticle,
  FeatureArticleList,
} from '../../types/response_types/FeatureArticleResponseTypes';
import { topFeatureOnsenList } from '../../data_sources/top_page/feature_list';
import R from '../../R';

type FeatureCollection = {
  topFeatureList: FeatureArticle[];
  featureList: FeatureArticle[];
};

export function accessTracking(
  feature: string,
  featureName: string,
  esYadoSearch: EsYadoSearch,
  authUserStore: AuthUserStore,
) {
  const track = () => {
    trackEvent(pageEvents.LIST_PAGE_SHOWN, {
      listType: 'Feature',
      ...esYadoSearch.toTrackingParams(),
      feature,
      pageName: featureName,
    });
  };

  when(
    () => authUserStore.isLoaded,
    () => {
      track();
    },
  );
  trackPixelSearch({
    search_string: featureName,
    content_category: 'feature',
  });
}
export function isNotFound(query: { feature?: string }, features: Features): boolean {
  if (query.feature && features[query.feature]) {
    return false;
  }

  return true;
}
export function getRelatedFeaturesLinkTexts(
  prefectureRelatedFeatures: RelatedFeaturesType | Null,
  searchCondition: OldSearchCondition,
):
  | Array<{
      link: LinkProps;
      text: string;
    }>
  | Null {
  if (!prefectureRelatedFeatures || prefectureRelatedFeatures.length === 0) return null;
  const featuresLinkTexts = prefectureRelatedFeatures
    .filter(entry => entry.count > 0)
    .map(entry => {
      const link = createYadoListLink('feature', {
        ...searchCondition,
        feature: entry.id,
      });
      return {
        link,

        text: entry.name,
      };
    });
  return featuresLinkTexts;
}

export function separateFeatureArticleList(featureArticleList: FeatureArticleList) {
  const filteredFeatureArticleList = featureArticleList.filter(
    featureArticle =>
      ![
        'best_dining',
        'image_select',
        'sakura202002',
        'beef201911',
        'kani201910',
        'view201910',
      ].includes(featureArticle.id),
  );

  const filteredList: FeatureCollection = filteredFeatureArticleList.reduce(
    (acc: FeatureCollection, feature: FeatureArticle) => {
      if (topFeatureOnsenList.includes(feature.id)) {
        acc.topFeatureList.push(feature);
      }
      acc.featureList.push(feature);
      return acc;
    },
    { topFeatureList: [], featureList: [] },
  );

  return filteredList;
}

export function getFeatureArticleLocaleImage(featureArticle: FeatureArticle) {
  switch (featureArticle.id) {
    case 'sakura_onsen':
      return R.assets.images.featured202303SakuraOnsen;
    case 'child_onsen':
      return R.assets.images.featured202303ChildOnsen;
    case 'cost_performance':
      return R.assets.images.featured202303CostPerformance;
    case 'japan_onsen_100':
      return R.assets.images.featured202303JapanOnsen100;
    case 'private201910':
      return R.assets.images.featured202303Private201910;
    case 'wagyu_onsen':
      return R.assets.images.featured202304WagyuOnsen;
    case 'photo_spot':
      return R.assets.images.featured202304PhotoSpot;
    case 'fireworks_onsen':
      return R.assets.images.featured202304FireworksOnsen;
    case 'new_listing':
      return R.assets.images.featured202304NewListing;
    case 'local_food':
      return R.assets.images.featured202304LocalFood;
    case 'pool_onsen':
      return R.assets.images.featured202305PoolOnsen;
    case 'relax_onsen':
      return R.assets.images.featured202305RelaxOnsen;
    case 'themepark':
      return R.assets.images.featured202305Themepark;
    case 'japan_festivals':
      return R.assets.images.featured202305JapanFestivals;
    case 'new_listing_202306':
      return R.assets.images.featured202306NewListing;
    case 'autumnleaves_spot':
      return R.assets.images.featured202306AutumnleavesSpot;
    case 'snow_onsen':
      return R.assets.images.featured202309SnowOnsen;
    case 'NEW_listing_2023JulySep':
      return R.assets.images.featured202309NewListing2023julysep;
    case 'NEW_listing_2023Nov':
      return R.assets.images.featured202311NewListing2023nov;
    case 'kyoritsu':
      return R.assets.images.featured202311Kyoritsu;
    case 'limited_plan':
      return R.assets.images.featuredLimited202312LimitedPlan;
    case 'NEW_listing_2023Dec':
      return R.assets.images.featured202312NewListing2023dec;
    case 'kani':
      return R.assets.images.featuredKani202312Crab;
    case 'NEW_listing_2024Jan':
      return R.assets.images.featured202401NewListing2024jan;
    case 'NEW_listing_2024Feb':
      return R.assets.images.featured202402NewListing2024feb;
    case 'NEW_listing_2024Mar':
      return R.assets.images.featured202403NewListing2024mar;
    case 'limited_plan_2024Apr':
      return R.assets.images.featuredLimited202404LimitedPlan;
    default:
      return featureArticle.image;
  }
}
