/*
 *  Firebase の tracking を抽象化
 */

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

import { actionEvents, isPageEvent } from './trackingEvents';
import { getWamazingSubdomain, isCn } from '../../config/locales/i18n';
import ENV from '../../constants/ENV';
import { trackAdwords } from './tracks/trackAdwords';
import { isServer } from '../../shared/utils/next_helper';
import baiduTongjiTracker from './baiduTongjiTracker';
import { Suggestion } from '../../types/SuggestionTypes';
import SearchCondition from '../search/SearchCondition';
import {
  isAreaSuggestion,
  isRegionSuggestion,
  isYadoSuggestion,
} from '../../helpers/suggestion_helper';
import SentryReporter from './SentryReporter';

type TrackParams = {
  revenue?: number;
  [key: string]: (string | number | boolean) | Null;
};

const CAN_LOG = process.env.NODE_ENV !== 'production' && !process.env.NEXT_PUBLIC_FORBID_GA_LOG;

export function trackEvent(eventName: string, params?: TrackParams) {
  if (isServer()) return;

  if (CAN_LOG) console.log('trackEvent', eventName);

  // 毎回 UTM タグの campaign params があったら上書きする, window にアクセスしてるのでよろしくはない…
  // 入れてる経緯はこちら https://wamazing.kibe.la/@yuichi_tateno/955

  const value = params?.revenue ?? 0;

  if (!isCn()) {
    ReactGA.event({
      category: 'WebYado',
      action: eventName,
      label: eventName,
      value,
      nonInteraction: isPageEvent(eventName),
    });
  }

  if (isCn() && ENV.ENVIRONMENT === 'production') {
    baiduTongjiTracker.trackEvent(eventName, eventName, value);
  }
}

export function signOutTrackingUser() {
  if (isServer()) return;

  trackEvent(actionEvents.USER_SIGNED_OUT);

  if (!isCn()) {
    ReactGA.set({
      userId: null,
    });
  }
}

export function trackPageView(pathname: string, query: string) {
  if (isServer()) return;
  const path = `${pathname}${query}`;

  if (isCn() && ENV.ENVIRONMENT === 'production') {
    // baidu tongjiのページビューイベント
    baiduTongjiTracker.trackPageView(path);
    return;
  }

  if (!isCn()) {
    ReactGA.pageview(path);
    ReactPixel.pageView();
    trackAdwords({
      isOnsen: path.includes('/yado/onsen'),
    });
  }
}

export function trackingSetup(href: string) {
  if (isServer()) return;
  const subDomain = getWamazingSubdomain();

  if (!isCn()) {
    const tagManagerArgs = {
      gtmId: ENV.GTM_IDS[subDomain],
    };
    TagManager.initialize(tagManagerArgs);

    ReactGA.initialize(ENV.GA_TRACKING_IDS[subDomain], {
      debug: CAN_LOG,
    });
    ReactGA.plugin.require('linkid');

    if (href.includes('/yado/onsen')) {
      ReactPixel.init(ENV.FB_PIXEL_IDS.ONSEN[subDomain]);
    } else {
      ReactPixel.init(ENV.FB_PIXEL_IDS[subDomain]);
    }
  }

  if (CAN_LOG) console.log('subdomain:', subDomain);
}
export function trackPixelSearch({
  search_string,
  content_category,
}: {
  search_string: string;
  content_category: string;
}) {
  if (isServer()) return;

  if (!isCn()) {
    ReactPixel.track('Search', {
      // イベント名はPixel固有の文字列
      search_string,
      content_category,
    });
  }
}

export function trackPixelPlanDetail({
  value,
  content_name,
  content_ids,
}: {
  value: number | Null;
  content_name: string;

  content_ids: string[];
}) {
  if (isServer()) return;

  if (!isCn()) {
    ReactPixel.track('ViewContent', {
      // イベント名はPixel固有の文字列
      value,
      currency: 'JPY',
      content_name,
      content_ids,
      content_type: 'product',
    });
  }
}

export function trackCreditCardAdded() {
  if (isServer()) return;
  trackEvent(actionEvents.CREDIT_CARD_ADDED);

  if (!isCn()) {
    ReactPixel.track('AddPaymentInfo', null);
    trackAdwords({
      eventName: actionEvents.CREDIT_CARD_ADDED,
    });
  }
}

export function trackPaymentWithoutAddingCreditCard() {
  if (isServer()) return;
  trackEvent(actionEvents.PAYMENT_WITHOUT_ADDING_CREDIT_CARD);

  if (!isCn()) {
    ReactPixel.track('AddPaymentInfo', null);
    trackAdwords({
      eventName: actionEvents.PAYMENT_WITHOUT_ADDING_CREDIT_CARD,
    });
  }
}

export function trackSearchSuggestion(
  eventName: string,
  suggestion: Suggestion,
  searchCondition: SearchCondition,
) {
  trackEvent(eventName, {
    suggestionLabel: suggestion.label,
    onsenArea: isAreaSuggestion(suggestion) ? suggestion.slug : '',
    onsenRegion: isRegionSuggestion(suggestion) ? suggestion.slug : '',
    onsenYado: isYadoSuggestion(suggestion) ? suggestion.yadoId : '',
    checkinDate: searchCondition.checkinDate || '',
    checkoutDate: searchCondition.checkoutDate || '',
    adultsCount: searchCondition.totalAdults,
    childrenCount: searchCondition.totalChildren,
    roomsCount: searchCondition.rooms.length,
  });
}

export function gtagSendUserId(userId: string, gaId: string) {
  try {
    gtag('event', 'user_id', {
      user_id: userId,
      user_properties: { crm_id: userId },
      send_to: gaId,
    });
  } catch (e) {
    SentryReporter.captureException(`gtag send userId error ${e}`);
  }
}

export function initializeBraze(userID?: string | null) {
  window.dataLayer.push({
    event: 'get_user_id',
    user_id: userID,
  });
}
