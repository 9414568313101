import { ExchangeRatesResponse, CurrencyCode } from '../../../types/ExchangeRates';
import fetchWithErrorThrowing from '../../../../shared/utils/fetchWithErrorThrowing';
import ENV from '../../../../constants/ENV';
import { getAcceptLanguage } from '../../../../config/locales/i18n';

type ExchangeRatesSnakeResponse = Array<{
  id: number;
  exchange_rate: number;
  from_currency: CurrencyCode;
  to_currency: CurrencyCode;
  updated_at: string;
}>;

export async function fetchExchangeRates(): Promise<ExchangeRatesResponse> {
  const uri = `${ENV.WAMAZING_API_ENDPOINT}/v1/exchange_rates`;

  const exchangeRate: ExchangeRatesResponse = await fetchWithErrorThrowing(uri, {
    headers: {
      'Accept-Language': getAcceptLanguage(),
      'X-WAPI-KEY': ENV.X_API_KEY,
    },
  })
    .then(res => res.json())
    .then((json: ExchangeRatesSnakeResponse) => {
      return json.map(item => ({
        fromCurrency: item.from_currency,
        toCurrency: item.to_currency,
        exchangeRate: Number(item.exchange_rate),
        updatedAt: new Date(item.updated_at),
      }));
    });

  return exchangeRate;
}
