import * as React from 'react';
import { isMobileResult } from 'ismobilejs';

type DeviceContextObject = {
  isMobile: isMobileResult;
  isBot: boolean;
  userAgent: string;
};

export const DeviceContext = React.createContext({
  isMobile: {},
  isBot: false,
  userAgent: '',
} as DeviceContextObject);

export type DeviceContextType = React.ContextType<typeof DeviceContext>;
