/*
回数をカウントしたいプロパティについて
-----------------------------------------------------
RN で使用している xxx_COUNT は使わず、下記のイベント名を使って
そのままユーザーの Property をインクリメントしていけば良い。
*/

/*
プロパティが必要なイベントもある
-----------------------------------------------------
コメントがついているイベント名は、追加のプロパティが必要です。
同じイベント名の方が計測が便利という @chacha644 のアイデアです。
例：
trackEvent(pageEvents.LIST_PAGE_SHOWN, { listType: 'Area' });
*/

/*
イベント名のルール
-----------------------------------------------------
pages:
ページ名には `_PAGE` と付ける。

actions:
何が + 何された => 主語 + 過去分詞形
be動詞は省略してください。

あまり固有なものをつくらず、なるべく汎用的な名前を使ったほうが
計測に役立つものになるかと思いますが、迷ったら相談しましょう。
例：
GOOD: actionEvents.ELEMENT_TAPPED, { element: 'Reservation Button' }
BAD:  actionEvents.RESERVATION_BUTTON_TAPPED
*/

const pages = <const>[
  'COUNTRY_TOP_PAGE_SHOWN',
  'TOP_PAGE_SHOWN',
  'LIST_PAGE_SHOWN',
  'YADO_PAGE_SHOWN',
  'PLAN_PAGE_SHOWN',
  'BOOKING_HISTORY_PAGE_SHOWN',
  'ONSEN_TOP_SHOWN',
  'ONSEN_LP_SHOWN',
  'ONSEN_AREA_REGION_YADO_LIST_SHOWN',
  'ONSEN_AREA_PREFECTURE_YADO_LIST_SHOWN',
  'ONSEN_FEATURE_YADO_LIST_SHOWN',
  'ONSEN_YADO_DETAIL_SHOWN',
  'ONSEN_YADO_DINNER_ROOM_MODAL_SHOWN',
  'ONSEN_YADO_ROOM_DINNERS_MODAL_SHOWN',
  'ONSEN_YADO_DINNER_ROOMS_MODAL_SHOWN',
  'ONSEN_YADO_ORDER_CREATE_SHOWN',
  'ONSEN_YADO_ORDER_PAYMENT_SHOWN',
  'ONSEN_YADO_ORDER_WECHAT_PAY_SHOWN',
  'ONSEN_YADO_ORDER_COMPLETE_SHOWN',
  'ONSEN_YADO_BOOKING_INDEX_SHOWN',
  'ONSEN_YADO_BOOKING_DETAIL_SHOWN',
  'ONSEN_YADO_DETAIL_PLANS_SOLD_OUT_SHOWN',
  'ONSEN_YADO_SERVICE_FEATURE_MODAL_SHOWN',
  'COUPON_BANNER_SHOWN',
  'ONSEN_PROMO_PAGE_SHOWN',
  'ONSEN_FEATURED_PAGE_SHOWN',
  'ONSEN_SUBSCRIBE_WECHAT_PAGE_SHOWN',
  'ONSEN_WECHAT_QR_LINK_PAGE_SHOWN',
  'ONSEN_PAYLATER_PAYMENT_PAY_PAGE_SHOWN',
  'ONSEN_PAYLATER_PAYMENT_COMPLETE_PAGE_SHOWN',
  'ONSEN_CAMPAIGN_SAKURA_PAGE_SHOWN',
  'ONSEN_PROMO_202003_PAGE_SHOWN',
  'ONSEN_PAGE_VIEW',
  'ONSEN_AREA_DETAIL_SHOWN',
];
const actions = <const>[
  'WECHAT_WIDGET_CLICKED',
  'WECHAT_WIDGET_CONFIRMED',
  'USER_SIGNED_OUT',
  'PRICE_SEARCH_COMPLETED',
  'BOOKING_FAILED',
  'BOOKING_COMPLETED',
  'BOOKING_CANCEL_REASON_SKIPPED',
  'REGISTRATION_COMPLETED',
  'CREDIT_CARD_ADDED',
  'PAYMENT_WITHOUT_ADDING_CREDIT_CARD',
  'CLICK_NEARBY_YADO',
  'ONSEN_PICKUP_REQUESTED_IN_ORDER_COMPLETE',
  'ONSEN_PICKUP_REQUESTED_IN_BOOKING_PICKUP_REQUST',
  'ONSEN_PICKUP_MODIFIED_IN_BOOKING_PICKUP_REQUST',
  'ONSEN_LP_SCROLL_DOWN_PRESSED',
  'ONSEN_LP_YADO_CLICKED',
  'ONSEN_ON_THE_DAY_PICKUP_CALLED',
  'ONSEN_ACCOUNT_MODAL_BEFORE_PAYMENT_OPEN',
  'ONSEN_ACCOUNT_MODAL_BEFORE_PAYMENT_CLOSE',
  'ONSEN_ACCOUNT_MODAL_BEFORE_PAYMENT_SIGNIN_CLICKED',
  'ONSEN_ACCOUNT_MODAL_BEFORE_PAYMENT_SIGNUP_CLICKED',
  'ONSEN_ON_THE_DAY_PICKUP_ACCEPTED',
  'ONSEN_YADO_CANCELED',
  'ONSEN_BOOKING_COMPLETED',
  'ONSEN_TOP_SEARCH_BUTTON_CLICKED',
  'ONSEN_AREA_REGION_YADO_LIST_SEARCH_BUTTON_CLICKED',
  'ONSEN_AREA_PREFECTURE_YADO_LIST_SEARCH_BUTTON_CLICKED',
  'ONSEN_FEATURE_YADO_LIST_SEARCH_BUTTON_CLICKED',
  'ONSEN_YADO_DETAIL_SEARCH_BUTTON_CLICKED',
  'COUPON_BANNER_CLICKED',
  'ONSEN_PROMO_PAGE_CTA_1_CLICKED',
  'ONSEN_PROMO_PAGE_CTA_2_CLICKED',
  'ONSEN_FEATURED_LINK_CLICKED',
  'ONSEN_FEATURED_PAGE_YADO_LINK_CLICKED',
  'ONSEN_AREA_INTRODUCTION_TOGGLE_BUTTON_CLICKED',
  'ONSEN_AREA_MEDIA_ARTICLE_TOGGLE_BUTTON_CLICKED',
  'ONSEN_AREA_MEDIA_ARTICLE_LINK_CLICKED',
  'ONSEN_AREA_DETAIL_RECOMMENDATION_CLICKED',
  'ONSEN_KEYWORD_SEARCH_SUGGESTION_SELECTED',
  'ONSEN_KEYWORD_SEARCH_NO_SUGGESTION_MATCH',
  // このモーダルはURLを持っていないのでactionEventとしてカウント
  'ONSEN_WECHAT_LOGIN_MODAL_SHOWN',
  'ONSEN_WECHAT_LOGIN_TRIED',
  'ONSEN_WECHAT_LOGIN_SUCCEEDED',
  'ONSEN_WECHAT_LOGIN_FAILED',
  'ONSEN_WECHAT_PAY_MODAL_SHOWN',
  'ONSEN_YADO_DETAIL_RECOMMENDATION_CLICKED',
  'ONSEN_YADO_ORDER_WECHAT_PAY_TRIED',
  'ONSEN_YADO_ORDER_WECHAT_PAY_SUCCEEDED',
  'ONSEN_YADO_ORDER_WECHAT_PAY_FAILED',
  'ONSEN_PAYLATER_PAY_BUTTON_CLICKED',
  'ONSEN_GO_TO_BOOKING_CONFIRMATION_PAGE_BUTTON_CLICKED',
  'ONSEN_SEARCH_FILTER_CONDITION_CHECKED',
  'ONSEN_AREA_DETAIL_SEARCH_BUTTON_CLICKED',
];

const titleize = (word: string) => word.charAt(0) + word.toLowerCase().slice(1);

type PageEvents = typeof pages[number];
type ActionEvents = typeof actions[number];

export const pageEvents: { [key in PageEvents]: string } = pages.reduce((acc, eventName) => {
  // eslint-disable-next-line no-param-reassign
  acc[eventName] = `WebYado ${eventName
    .split('_')
    .map(n => titleize(n))
    .join(' ')}`;
  return acc;
}, {} as { [key in PageEvents]: string });

export const actionEvents: { [key in ActionEvents]: string } = actions.reduce((acc, eventName) => {
  // eslint-disable-next-line no-param-reassign
  acc[eventName] = `WebYado ${eventName
    .split('_')
    .map(n => titleize(n))
    .join(' ')}`;
  return acc;
}, {} as { [key in ActionEvents]: string });

export function isPageEvent(eventName: string): boolean {
  return Object.values(pageEvents).includes(eventName);
}
