import Head from 'next/head';
import React from 'react';
import { sprintf } from 'sprintf-js';

import ENV from '../../constants/ENV';

type Country = 'tw' | 'hk' | 'cn' | 'www';
type Lang = 'zh-Hant-TW' | 'zh-Hant-HK' | 'zh-Hans-CN' | 'en';

const sites: Array<{ country: Country; lang: Lang }> = [
  {
    country: 'tw',
    lang: 'zh-Hant-TW',
  },
  {
    country: 'hk',
    lang: 'zh-Hant-HK',
  },
  {
    country: 'cn',
    lang: 'zh-Hans-CN',
  },
  {
    country: 'www',
    lang: 'en',
  },
];

function makeAlternateHref(path: string, country: Country): string {
  if (country === 'cn') {
    return `${ENV.YADO_CN_URL}${path}`;
  }
  return `${sprintf(ENV.WAMAZING_COM_ENDPOINT, country)}${path}`;
}

export default ({ path }: { path: string }) => (
  <Head>
    {sites.map(site => (
      <link
        rel="alternate"
        href={makeAlternateHref(path, site.country)}
        hrefLang={site.lang}
        key={site.lang}
      />
    ))}
  </Head>
);
