import { isServer } from '../shared/utils/next_helper';
import { isCn } from '../config/locales/i18n';

export function isWechat(): boolean {
  if (isServer()) return false;
  return /micromessenger/i.test(navigator.userAgent);
}

export function isCnWebBrowser(): boolean {
  return isCn() && !isWechat();
}
