export const wait = (delay: number): Promise<number> =>
  new Promise((resolve: Function) => {
    if (delay === 0) {
      resolve(delay);
    } else {
      setTimeout(() => {
        resolve(delay);
      }, delay);
    }
  });
