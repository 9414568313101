import { CurrencyCode, CurrencySymbol } from '../../legacy/types/ExchangeRates';

const CURRENCY_SYMBOL_MAP: {
  [key in CurrencyCode]: CurrencySymbol;
} = {
  JPY: '¥',
  TWD: '$',
  HKD: '$',
  CNY: '¥',
  USD: '$',
};

function exchangePrice(priceJpy: number | Null, exchangeRate: number | Null): number | Null {
  if (priceJpy != null && exchangeRate != null) {
    return Math.ceil(priceJpy * exchangeRate);
  }

  return null;
}

function exchangePriceForActivity(
  priceJpy: number | Null,
  exchangeRate: number | Null,
  locale: string,
): number | Null {
  if (priceJpy != null && exchangeRate != null) {
    return locale === 'en'
      ? Math.round(priceJpy * exchangeRate * 100) / 100
      : Math.round(priceJpy * exchangeRate);
  }

  return null;
}

function reverseExchangePrice(
  priceUserCurrency: number | Null,
  exchangeRate: number | Null,
): number | Null {
  if (priceUserCurrency != null && exchangeRate != null) {
    return Math.ceil(priceUserCurrency / exchangeRate);
  }

  return null;
}

class ExchangeRate {
  symbol: CurrencySymbol;

  constructor(readonly rate: number, readonly currency: CurrencyCode) {
    this.symbol = CURRENCY_SYMBOL_MAP[currency];
  }

  exchange(priceJpy: number): number | Null {
    return exchangePrice(priceJpy, this.rate);
  }

  exchangeForActivity(priceJpy: number | Null, locale: string) {
    return exchangePriceForActivity(priceJpy, this.rate, locale);
  }

  reverseExchange(priceUserCurrency: number): number | Null {
    return reverseExchangePrice(priceUserCurrency, this.rate);
  }
}

export default ExchangeRate;
