import { actionEvents } from '../trackingEvents';
import { getWamazingSubdomain } from '../../../config/locales/i18n';
import ENV from '../../../constants/ENV';

type conversionParamsType = {
  google_conversion_id: string;
  google_conversion_label?: string;
  google_conversion_value?: number;
  google_conversion_currency?: string;
  google_custom_params?: any;
  google_remarketing_only: boolean;
};

const TARGET_SUBDOMAINS = ['tw', 'hk', 'www'];

const conversionLabelMap = {
  tw: {
    [actionEvents.BOOKING_COMPLETED]: 'c3D5CNmMyH0Qqea7hQM',
    [actionEvents.REGISTRATION_COMPLETED]: 'ZeGFCIaHyH0Qqea7hQM',
    [actionEvents.ONSEN_BOOKING_COMPLETED]: 'D9ZQCJPbs7cBEKqT5skC', // 温泉やど予約完了
  },
  hk: {
    [actionEvents.BOOKING_COMPLETED]: 'ggzJCL6zjn4QiL3XhAM',
    [actionEvents.REGISTRATION_COMPLETED]: 'bbVWCLDDoX4QiL3XhAM',
    [actionEvents.ONSEN_BOOKING_COMPLETED]: 'D9ZQCJPbs7cBEKqT5skC',
  },
  www: {
    [actionEvents.BOOKING_COMPLETED]: 'Qn4OCLDMj5YBEJSIpeoC',
    [actionEvents.REGISTRATION_COMPLETED]: 'T9AdCKnggpYBEJSIpeoC',
    [actionEvents.ONSEN_BOOKING_COMPLETED]: '',
  },
};

const track = (conversionParams: object) => {
  if ((window as any).google_trackConversion) {
    (window as any).google_trackConversion(conversionParams);
  } else {
    setTimeout(track, 100);
  }
};

type TrackAdwordsParams = {
  eventName?: string | Null;
  params?: {
    [key: string]: any;
  };
  isOnsen?: boolean | Null;
};

export const trackAdwords = ({ eventName, params, isOnsen }: TrackAdwordsParams = {}) => {
  if (typeof window === 'undefined') {
    return;
  }

  const subdomain = getWamazingSubdomain();

  if (subdomain === 'cn' || subdomain === 'jp') {
    return;
  }

  const conversionId = isOnsen ? ENV.ADWORDS_IDS.ONSEN[subdomain] : ENV.ADWORDS_IDS[subdomain];

  if (conversionId === 'NONE') {
    return;
  }

  // リマケタグ
  const conversionParams: conversionParamsType = {
    google_conversion_id: conversionId,
    google_remarketing_only: false,
  };

  if (eventName == null) {
    conversionParams.google_custom_params = (window as any).google_tag_params;
    // 予約完了CV
    // TODO: Replace event name with constant
    conversionParams.google_remarketing_only = true;
  }

  if (TARGET_SUBDOMAINS.includes(subdomain)) {
    if (eventName === actionEvents.BOOKING_COMPLETED) {
      const revenue = params && params.revenue ? params.revenue : null;

      if (revenue && typeof revenue === 'number') {
        conversionParams.google_conversion_value = revenue;
      }

      conversionParams.google_conversion_currency = 'JPY'; // Always JPY no matter what.
      // eslint-disable-next-line max-len
      conversionParams.google_conversion_label =
        conversionLabelMap[subdomain][actionEvents.BOOKING_COMPLETED];
    } else if (eventName === actionEvents.REGISTRATION_COMPLETED) {
      // eslint-disable-next-line max-len
      conversionParams.google_conversion_label =
        conversionLabelMap[subdomain][actionEvents.REGISTRATION_COMPLETED];
    } else if (params && actionEvents.ONSEN_BOOKING_COMPLETED) {
      const { revenue, bookingId } = params;

      if (typeof revenue === 'number') {
        conversionParams.google_conversion_value = revenue;
      }

      conversionParams.google_conversion_currency = 'JPY'; // Always JPY no matter what.
      conversionParams.google_custom_params = {
        transaction_id: bookingId || '',
      };
      // eslint-disable-next-line max-len
      conversionParams.google_conversion_label =
        conversionLabelMap[subdomain][actionEvents.ONSEN_BOOKING_COMPLETED];
    }
  }

  track(conversionParams);
};
