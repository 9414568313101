// @no-wa-shared

import { ILocalStorage } from '../../legacy/data_sources/local_storages/ILocalStorage';
import AuthUserStorage from './AuthUserStorage';
import SessionStorage from '../../legacy/data_sources/local_storages/SessionStorage';

const storages: {
  [key: string]: ILocalStorage;
} = {};

function registerStorage<T extends ILocalStorage>(storage: T): T {
  if (storages[storage.name]) {
    throw new Error(`storageName ${storage.name} is already defined`);
  }

  storages[storage.name] = storage;
  return storage;
}

export const authUserStorage: AuthUserStorage = registerStorage(new AuthUserStorage());

export const sessionStorage: SessionStorage = registerStorage(new SessionStorage());
export const clearAllStoragesForTests = () => {
  Array.from(Object.values(storages)).forEach((storage: ILocalStorage) => storage.clear());
};
export const setHydrateLocalStorages = async (hydrate: Function) =>
  Promise.all(
    Array.from(Object.values(storages)).map((storage: ILocalStorage) =>
      hydrate(storage.name, storage),
    ),
  );
