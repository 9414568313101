/* eslint-disable no-underscore-dangle */
/* eslint-disable no-multi-assign */
import { v4 as uuidv4 } from 'uuid';

import ENV from '../constants/ENV';
import { getCookieSessionId, setCookieSessionId } from '../root/cookies';

export function runSift(userId: number | Null) {
  if (!ENV.SIFT_BEACON_KEY) return null;

  if (!getCookieSessionId()) setCookieSessionId(`${uuidv4()}-${Date.now()}`);

  if (window._sift) {
    const _sift = (window._sift = window._sift || []);
    _sift.push(['_setAccount', ENV.SIFT_BEACON_KEY]);
    _sift.push(['_setUserId', userId || '']);
    _sift.push(['_setSessionId', getCookieSessionId()]);
    _sift.push(['_trackPageview']);
  }
}
