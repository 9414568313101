import ENV from '../../constants/ENV';
import { getAcceptLanguage } from '../../config/locales/i18n';
import fetchWithErrorThrowing from '../../shared/utils/fetchWithErrorThrowing';
import { sessionStorage as localSessionStorage } from '../local_storages';
import { TravelResponse, SecureUserProfileResponse } from '../../models/account/User';

const baseWaServerURI = ENV.WAMAZING_API_ENDPOINT;
const getUserPath = '/v1/user';

export interface SecureCreditCard {
  id: number;
  primary: boolean;
  lower_four_digits: string;
  exp_month: string;
  exp_year: string;
  holder_name: string;
  brand: string;
}

export interface SecureCreditCardGmo {
  id: number;
  primary: boolean;
  lower_four_digits: string;
  exp_month: number | null;
  exp_year: number | null;
  holder_name: string | null;
  brand: string | null;
}

export interface SecureUser {
  access_token: string;
  authorized_at: string;
  confirmed_at: string;
  email: string;
  refresh_token: string;
}

interface GetUserData {
  user: {
    id: number;
    created_at: Date;
    current_travel: TravelResponse[];
    secure_user: SecureUser;
    secure_credit_cards: SecureCreditCard[];
    secure_gmo_credit_cards: SecureCreditCardGmo[];
    secure_user_profile: SecureUserProfileResponse;
  };
}

export async function getUser(): Promise<GetUserData | null> {
  const uri = `${baseWaServerURI}${getUserPath}`;
  return fetchWithErrorThrowing(uri, {
    headers: {
      Authorization: `Bearer ${localSessionStorage.accessToken || ''}`,
      'Accept-Language': getAcceptLanguage(),
      'X-Client-Type': 'web',
    },
  })
    .then(res => res.json())
    .catch(() => null);
}
