import {
  SecureCreditCard,
  SecureCreditCardGmo,
  SecureUser,
} from '../../data_sources/networks/user';

export type TravelResponse = {};
export type SecureUserProfileResponse = {};
export default class User {
  constructor(
    readonly userId: number | Null,
    readonly email: string,
    readonly createdAt: Date | undefined,
    readonly currentTravel: TravelResponse[] | Null,
    readonly secureUser: SecureUser,
    readonly secureCreditCards: SecureCreditCard[],
    readonly secureGmoCreditCards: SecureCreditCardGmo[],
    readonly secureUserProfile: SecureUserProfileResponse | Null,
  ) {}
}
