import { LocaleType } from '../legacy/types/LocaleType';

export type OGPLocaleType = 'ja_JP' | 'zh_TW' | 'zh_HK' | 'zh_CN' | 'en_US';

export function getOGPLocale(locale: LocaleType): OGPLocaleType {
  switch (locale) {
    case 'en':
      return 'en_US';
    case 'zh_tw':
      return 'zh_TW';
    case 'zh_hk':
      return 'zh_HK';
    case 'zh_cn':
      return 'zh_CN';
    default:
      return 'ja_JP';
  }
}

export enum OGPTypeContent {
  Website = 'website',
  Article = 'article',
}
