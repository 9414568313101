import qs from 'qs';

import { YadoDetailJson, YadoLowestPrice } from '../../../types/response_types/YadoResponseTypes';
import { Feature } from '../../../types/SuggestionTypes';
import fetchWithErrorThrowing from '../../../shared/utils/fetchWithErrorThrowing';
import { getAcceptLanguage } from '../../../config/locales/i18n';
import { PriceSearchApiParams } from '../../../shared/utils/search_helper';
import SentryReporter from '../../../models/trackings/SentryReporter';
import { getReplaceBaseURI } from '../../../shared/utils/url_helper';
import { removeViewedYado } from '../../local_storages/ViewedYadoStorage';
import { YadoReportViewContent } from '../../../types/response_types/YadoReportViewResponseTypes';

const yadoPath = (yadoId: string) => `/api/v1/yados/${yadoId}`;

const yadoWamazingExclusivePath = '/api/v1/yados?has_wamazing_exclusive_plan=true';

const yadosByAreaSlugPath = (areaSlug: string) => `/api/v1/yados?onsen_area_id=${areaSlug}`;

const yadosByRegionSlugPath = (regionSlug: string) => `/api/v1/yados?region_slug=${regionSlug}`;

const yadosByPrefectureIdPath = (prefectureId: string) =>
  `/api/v1/yados?prefecture_id=${prefectureId}`;

const yadosByFeaturePath = (feature: Feature) => `/api/v1/yados?${feature.query}`;

const yadosPricesPath = '/api/v1/yados';

type YadoLowestPriceResponse = {
  id: string;
  lowestPrice: number;
  lowestPriceIncludingTax: number;
  hasTwoMeals: boolean;
};

type YadoLowestPricesResponse = {
  yados: YadoLowestPriceResponse[];
};

type CalendarPriceSearchApiParams = {
  checkin_date: string;
  rooms: Array<{
    adults: number;
    children: number;
    child_ages: number[];
  }>;
};

export type CalendarPrice = {
  date: string;
  price: number;
};

export type CalendarPriceResponse = {
  prices: CalendarPrice[];
};

export type PopularYadoRoomPlan = {
  yado: YadoDetailJson;
  popularRoomPlanIds: string[];
  rankIndex: number;
};

export async function fetchYadoDetail(yadoId: string): Promise<YadoDetailJson | null> {
  const baseURI = getReplaceBaseURI();
  const uri = `${baseURI}${yadoPath(yadoId)}`;
  try {
    const yadoResponse = await fetchWithErrorThrowing(encodeURI(uri), {
      headers: {
        'Accept-Language': getAcceptLanguage(),
      },
    });

    return await yadoResponse.json();
  } catch (error) {
    console.log(`fetchYadoDetail-error(id: ${yadoId}):`, error);
    removeViewedYado(yadoId);

    return null;
  }
}

export async function fetchYadosWamazingExclusive(): Promise<YadoDetailJson[] | null> {
  const baseURI = 'https://yado-backend-replace.wamazing.com';
  const uri = `${baseURI}${yadoWamazingExclusivePath}`;
  try {
    const yadosResponse = await fetchWithErrorThrowing(encodeURI(uri), {
      headers: {
        'Accept-Language': getAcceptLanguage(),
      },
    });
    const yadosJson = await yadosResponse.json();
    return yadosJson;
  } catch (error) {
    console.log(`fetchYadosWamazingExclusiver-error:`, error);
    return null;
  }
}

export async function fetchYadosByAreaSlug(areaSlug: string): Promise<YadoDetailJson[]> {
  const baseURI = getReplaceBaseURI();
  const uri = `${baseURI}${yadosByAreaSlugPath(areaSlug)}`;
  const yadosResponse = await fetchWithErrorThrowing(encodeURI(uri), {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  });
  const yadosJson = await yadosResponse.json();
  return yadosJson;
}
export async function fetchYadosByRegionSlug(regionSlug: string): Promise<YadoDetailJson[]> {
  const baseURI = getReplaceBaseURI();
  const uri = `${baseURI}${yadosByRegionSlugPath(regionSlug)}`;
  const yadosResponse = await fetchWithErrorThrowing(encodeURI(uri), {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  });
  const yadosJson = await yadosResponse.json();
  return yadosJson;
}

export async function fetchYadosByPrefectureId(prefectureId: string): Promise<YadoDetailJson[]> {
  const baseURI = getReplaceBaseURI();
  const uri = `${baseURI}${yadosByPrefectureIdPath(prefectureId)}`;
  const yadosResponse = await fetchWithErrorThrowing(encodeURI(uri), {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  });
  const yadosJson = await yadosResponse.json();
  return yadosJson;
}

export async function fetchYadosByFeature(feature: Feature): Promise<YadoDetailJson[]> {
  const baseURI = getReplaceBaseURI();
  const uri = `${baseURI}${yadosByFeaturePath(feature)}`;
  const yadosResponse = await fetchWithErrorThrowing(encodeURI(uri), {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  });
  const yadosJson = await yadosResponse.json();
  return yadosJson;
}

export async function fetchYadoPrices(
  yadoIds: string[],
  searchParams: PriceSearchApiParams,
): Promise<YadoLowestPrice[]> {
  const baseURI = getReplaceBaseURI();
  if (!searchParams.checkin_date || !searchParams.checkout_date) return [];

  const searchParamString = qs.stringify(searchParams).replace(/%5B\d+%5D/g, '%5B%5D');
  const yadoIdsParamString = yadoIds.map(id => encodeURI(`yado_ids[]=${id}`)).join('&');
  const paramString = `?${searchParamString}&${yadoIdsParamString}`;
  const uri = `${baseURI}${yadosPricesPath}${paramString}`;
  const prices = await fetchWithErrorThrowing(uri, {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  })
    .then(async res => {
      return ((await res.json()) as YadoLowestPricesResponse).yados.map(
        (p: YadoLowestPriceResponse): YadoLowestPrice => ({
          yadoId: p.id,
          priceIncludingTax: p.lowestPriceIncludingTax,
          priceExcludingTax: p.lowestPriceIncludingTax,
          hasTwoMeals: p.hasTwoMeals,
        }),
      );
    })
    .catch(error => {
      SentryReporter.captureException(`Failed to fetch yado lowest prices ${uri} ${error.message}`);
      return [];
    });
  return prices;
}

export async function fetchPopularYadosAndRoomPlans(
  areaSlug: string,
): Promise<PopularYadoRoomPlan[]> {
  const baseURI = getReplaceBaseURI();
  const uri = `${baseURI}${yadosByAreaSlugPath(areaSlug)}&popular=3`;
  const popularYadoResponse = await fetchWithErrorThrowing(encodeURI(uri), {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  });
  const popularYadoRoomPlans = await popularYadoResponse.json();
  return popularYadoRoomPlans;
}

export async function fetchPopularYadosAndRoomPlansByRegion(
  regionSlug: string,
  rankCount: number,
): Promise<PopularYadoRoomPlan[]> {
  const baseURI = getReplaceBaseURI();
  const uri = `${baseURI}${yadosByRegionSlugPath(regionSlug)}&popular=${rankCount}`;
  const popularYadoResponse = await fetchWithErrorThrowing(encodeURI(uri), {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  });
  const popularYadoRoomPlans = await popularYadoResponse.json();
  return popularYadoRoomPlans;
}

export async function fetchRecentPopularYadosAndRoomPlansByRegion(
  regionSlug: string,
  rankCount: number,
): Promise<PopularYadoRoomPlan[] | null> {
  const baseURI = getReplaceBaseURI();
  const date = new Date();
  const nowDate = date.toISOString().substring(0, 10);
  date.setDate(date.getDate() - 30);
  const monthBeforeDate = date.toISOString().substring(0, 10);
  const uri = `${baseURI}${yadosByRegionSlugPath(
    regionSlug,
  )}&from=${monthBeforeDate}&to=${nowDate}&popular=${rankCount}`;
  try {
    const popularYadoResponse = await fetchWithErrorThrowing(encodeURI(uri), {
      headers: {
        'Accept-Language': getAcceptLanguage(),
      },
    });
    const popularYadoRoomPlans = await popularYadoResponse.json();
    return popularYadoRoomPlans;
  } catch (error) {
    console.log(`fetchRecentPopularYadosAndRoomPlansByRegion`, error);
    return null;
  }
}

export async function fetchPopularYadosAndRoomPlansByArea(
  regionSlug: string,
  rankCount: number,
): Promise<PopularYadoRoomPlan[]> {
  const baseURI = getReplaceBaseURI();
  const uri = `${baseURI}${yadosByAreaSlugPath(regionSlug)}&popular=${rankCount}`;
  const popularYadoResponse = await fetchWithErrorThrowing(encodeURI(uri), {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  });
  const popularYadoRoomPlans = await popularYadoResponse.json();
  return popularYadoRoomPlans;
}

export async function fetchYadoPricesPeriod(yadoIds: string[], adultCount: number = 2) {
  const baseURI = getReplaceBaseURI();
  const date = new Date();
  date.setDate(date.getDate() + 30);
  const checkInDateQuery = `checkin_date=${new Date(date).toISOString().substring(0, 10)}`;
  date.setDate(date.getDate() + 1);
  const checkOutDateQuery = `checkout_date=${new Date(date).toISOString().substring(0, 10)}`;
  const adultCountQuery = `rooms%5B%5D%5Badults%5D=${adultCount}`;
  const childCountQuery = 'rooms%5B%5D%5Bchildren%5D=0';
  const yadoIdsQuery = yadoIds.map(id => `yado_ids[]=${id}`).join('&');
  const uri = `${baseURI}${yadosPricesPath}?${checkInDateQuery}&${checkOutDateQuery}&${adultCountQuery}&${childCountQuery}&${yadoIdsQuery}`;
  const result = await fetchWithErrorThrowing(uri, {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  })
    .then(async res => {
      return ((await res.json()) as YadoLowestPricesResponse).yados.map(
        (p: YadoLowestPriceResponse): YadoLowestPrice => ({
          yadoId: p.id,
          priceIncludingTax: p.lowestPriceIncludingTax,
          priceExcludingTax: p.lowestPriceIncludingTax,
          hasTwoMeals: p.hasTwoMeals,
        }),
      );
    })
    .catch(error => {
      SentryReporter.captureException(`Failed to fetch yado lowest prices ${uri} ${error.message}`);
      return [];
    });
  return result;
}

export async function fetchCalendarPrices(
  yadoId: string,
  searchParams: CalendarPriceSearchApiParams,
): Promise<CalendarPriceResponse> {
  const baseURI = getReplaceBaseURI();
  const searchParamString = qs.stringify(searchParams).replace(/%5B\d+%5D/g, '%5B%5D');
  const uri = `${baseURI}${yadoPath(yadoId)}/calendar?${searchParamString}`;
  try {
    const calendarPrices = await fetchWithErrorThrowing(uri, {
      headers: {
        'Accept-Language': getAcceptLanguage(),
      },
    });
    const calendarPrice = await calendarPrices.json();
    return calendarPrice;
  } catch (error) {
    console.warn('fetchCalendarPrices', error);
    return { prices: [] };
  }
}

function checkIsReportView(input: unknown): input is YadoReportViewContent {
  return (
    !!input &&
    typeof input === 'object' &&
    'yadoReport' in input &&
    'yadoReporter' in input &&
    'yadoReportPlan' in input
  );
}

function checkIsReportViews(input: unknown): input is YadoReportViewContent[] {
  return Array.isArray(input) && input.every(item => checkIsReportView(item));
}

export async function getYadoReportViews(yadoID: string) {
  try {
    if (typeof yadoID !== 'string') {
      throw new Error('getYadoReportViews: yadoID is not valid');
    }

    const baseURI = getReplaceBaseURI();
    const uri = `${baseURI}${yadoPath(yadoID)}/report_views`;

    const reportViewsResponse = await fetchWithErrorThrowing(uri, {
      headers: {
        'Accept-Language': getAcceptLanguage(),
      },
    });
    const reportViews = await reportViewsResponse.json();

    if (checkIsReportViews(reportViews)) {
      return reportViews;
    }
    throw new Error('getYadoReportViews: data is not valid YadoReportViewContent[]');
  } catch (error) {
    console.log('getYadoReportViews-error:', error);
    return [];
  }
}
