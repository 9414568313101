import { CouponHistory, Coupon } from '../../../types/response_types/CouponResponseTypes';
import { sessionStorage } from '../../local_storages';
import fetchWithErrorThrowing from '../../../shared/utils/fetchWithErrorThrowing';
import { getAcceptLanguage } from '../../../config/locales/i18n';
import { getBaseURI } from '../../../shared/utils/url_helper';

const couponHistoriesPath = '/api/v1/coupon_histories';
const featuredCouponsPath = '/api/v1/coupons/featured';

type CouponCode = {
  code: string;
};

export async function fetchCouponHistories(): Promise<CouponHistory[]> {
  const baseURI = getBaseURI();
  const uri = `${baseURI}${couponHistoriesPath}`;
  const couponHistoriesResponse = await fetchWithErrorThrowing(uri, {
    headers: {
      Authorization: `Bearer ${sessionStorage.accessToken || ''}`,
      'Accept-Language': getAcceptLanguage(),
    },
  });
  const couponHistoriesJson: CouponHistory[] = await couponHistoriesResponse.json();
  return couponHistoriesJson;
}

export async function fetchFeaturedCoupons(): Promise<Coupon[]> {
  const baseURI = getBaseURI();
  const uri = `${baseURI}${featuredCouponsPath}`;
  const featuredCouponsResponse = await fetchWithErrorThrowing(uri, {
    headers: {
      'Accept-Language': getAcceptLanguage(),
    },
  });
  const featuredCouponsJson: Coupon[] = await featuredCouponsResponse.json();
  return featuredCouponsJson;
}

export async function postCouponHistories(requestBody: CouponCode): Promise<CouponHistory[]> {
  const baseURI = getBaseURI();
  const uri = `${baseURI}${couponHistoriesPath}`;
  const couponHistoriesResponse = await fetchWithErrorThrowing(uri, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.accessToken || ''}`,
      'Accept-Language': getAcceptLanguage(),
    },
    body: JSON.stringify(requestBody),
    method: 'POST',
  });

  const couponHistoriesJson: CouponHistory[] = await couponHistoriesResponse.json();
  return couponHistoriesJson;
}
