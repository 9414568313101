import { action, computed, observable } from 'mobx';

import { sessionStorage } from '../../../data_sources/local_storages';
import { signOutTrackingUser } from '../../trackings/Tracking';
import SessionStorage from '../../../legacy/data_sources/local_storages/SessionStorage';
import User from '../../account/User';
import {
  removeCookieRefreshToken,
  getCookieRefreshToken,
  setCookieRefreshToken,
  getCookieAccessToken,
  removeCookieAccessToken,
  getCookieSessionId,
} from '../../../root/cookies';
import {
  getUser,
  SecureCreditCard,
  SecureCreditCardGmo,
  SecureUser,
} from '../../../data_sources/networks/user';

export type SIGN_IN_LOAD_STATE = 'before' | 'loading' | 'after';
export type TravelResponse = {};
export type SecureUserProfileResponse = {};

export default class AuthUserStore {
  private user: User | Null = null;

  @observable
  signInLoadState: SIGN_IN_LOAD_STATE = 'before';

  private session: SessionStorage;

  constructor() {
    this.session = sessionStorage;
  }

  get userId(): number | Null {
    return this.user?.userId;
  }

  get email(): string {
    return this.user?.email || '';
  }

  get createdAt(): Date | undefined {
    return this.user?.createdAt;
  }

  get currentTravel(): TravelResponse[] | Null {
    return this.user?.currentTravel;
  }

  get secureUser(): SecureUser | Null {
    return this.user?.secureUser;
  }

  get secureCreditCards(): SecureCreditCard[] | Null {
    return this.user?.secureCreditCards;
  }

  get secureGmoCreditCards(): SecureCreditCardGmo[] | Null {
    return this.user?.secureGmoCreditCards;
  }

  get secureUserProfile(): SecureUserProfileResponse | Null {
    return this.user?.secureUserProfile;
  }

  async signIn() {
    try {
      this.setSignInLoadState('loading');
      this.updateSessionByCookies();
      if (this.session.accessToken) {
        const res = await getUser();
        if (res != null) {
          this.user = new User(
            res.user.id,
            res.user.secure_user.email,
            res.user.created_at,
            res.user.current_travel,
            res.user.secure_user,
            res.user.secure_credit_cards,
            res.user.secure_gmo_credit_cards,
            res.user.secure_user_profile,
          );
        }
      }
    } finally {
      this.setSignInLoadState('after');
    }
  }

  @action
  private setSignInLoadState(signInLoadState: SIGN_IN_LOAD_STATE): void {
    this.signInLoadState = signInLoadState;
  }

  async signOut(): Promise<boolean> {
    signOutTrackingUser();
    removeCookieAccessToken();
    removeCookieRefreshToken();
    this.clearSession();
    this.user = null;
    return true;
  }

  clearSession(): void {
    this.session.clear();
  }

  updateTokenInCookie(refreshToken: string | Null) {
    const cookieToken: string | Null = getCookieRefreshToken();

    if (cookieToken && refreshToken && cookieToken === refreshToken) {
      return;
    }

    if (refreshToken) {
      setCookieRefreshToken(refreshToken);
    }
  }

  // cookie の token で session の refresh_token を上書きする
  updateSessionByCookies() {
    const cookieRefreshToken: string | Null = getCookieRefreshToken();
    const cookieAccessToken: string | Null = getCookieAccessToken();
    const cookieSessionId: string | Null = getCookieSessionId();

    const shouldUpdateToken =
      cookieRefreshToken &&
      cookieRefreshToken.length > 0 &&
      cookieAccessToken &&
      this.session.accessToken !== cookieAccessToken;

    if (shouldUpdateToken) {
      this.session.updateTokens({
        refresh_token: cookieRefreshToken as string,
        access_token: cookieAccessToken as string,
        authorized_at: 'none',
        session_id: cookieSessionId as string,
      });
    }
  }

  @computed
  get isSignedIn(): boolean {
    return this.isLoaded && !!this.userId;
  }

  @computed
  get isLoading(): boolean {
    return this.signInLoadState === 'loading';
  }

  @computed
  get isLoaded(): boolean {
    return this.signInLoadState === 'after';
  }
}
