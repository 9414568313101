import R from '../../R';
import { subdomainUrl } from '../../shared/utils/url_helper';
import { isCn } from '../../config/locales/i18n';

export type JsonName =
  | 'prefectures'
  | 'cities'
  | 'spots'
  | 'features'
  | 'citySpots'
  | 'featureRelations'
  | 'prefectureDetails';

const JSON_NAMES: JsonName[] = [
  'prefectures',
  'cities',
  'spots',
  'features',
  'citySpots',
  'featureRelations',
  'prefectureDetails',
];

export async function fetchSearchJsons(): Promise<any> {
  const jsons = await Promise.all(JSON_NAMES.map(name => fetchSearchJson(name)));
  const jsonObj: { [key: string]: any } = {};
  JSON_NAMES.forEach((key, i) => {
    jsonObj[key] = jsons[i];
  });
  return jsonObj;
}

export async function fetchSearchJson(jsonName: JsonName): Promise<any> {
  const response = await fetch(`${isCn() ? '' : subdomainUrl()}${R.assets.jsons[jsonName]}`);
  return response.json();
}
