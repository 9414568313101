import { useEffect, useRef, useState } from "react";
import { getTranslation } from "../../libs/getTranslation";
import { LanguagesType } from "../../models/languagesType";
import { User, UserMenuList } from "../../models/user";
import { displayLinks, DisplayLinksType } from "../../utils/displayLinks";
import { WAProduct } from "../../utils/productType";
import useOnClickOutside from "../hooks/useOnClickOutside";
import HeadLogo from "../logoSvgs/HeadLogo";
import styles from "./Header.module.scss";
import { ProductList } from "./ProductList";
import { UserMenu } from "./UserMenu";
import { UserNotLogInMenu } from "./UserNotLogInMenu";
import { injectJsonLdScript, injectMargeGroupMeasurementTag } from "../../utils/seoUtils";

export type HeaderProps = {
  lang: LanguagesType;
  productName: WAProduct;
  user: User | undefined | null;
  logout: () => void;
  menu?: UserMenuList[];
  notLoginMenu?: UserMenuList[];
  content?: {
    center?: React.ReactNode;
    right?: React.ReactNode;
  };
  optionalSignupQuery?: string;
  checkIsUserOpen?: (val: boolean) => void;
  isH1Logo?: boolean;
};

export const Header: React.FC<HeaderProps> = ({
  lang,
  productName,
  user,
  logout,
  menu,
  notLoginMenu,
  content,
  optionalSignupQuery,
  checkIsUserOpen,
  isH1Logo = false,
}) => {
  const { t } = getTranslation(lang);
  const [isProductOpen, setIsProductOpen] = useState<boolean>(false);
  const [isUserOpen, setIsUserOpen] = useState<boolean>(false);
  const displayedLinks: DisplayLinksType = displayLinks[lang as LanguagesType];
  const userMenuRef = useRef<HTMLDivElement>(null);

  const handleProductOpen = () => {
    setIsProductOpen((prev) => !prev);
  };

  const handleUserOpen = () => {
    setIsUserOpen((prev) => !prev);
  };
  useOnClickOutside(userMenuRef, () => setIsUserOpen(false));

  useEffect(() => {
    if (checkIsUserOpen) {
      checkIsUserOpen(isUserOpen);
    }
  }, [isUserOpen, checkIsUserOpen]);

  useEffect(() => {
    // マルジュ　グループ計測タグをコンポーネントマウント時に追加する
    injectMargeGroupMeasurementTag()
    injectJsonLdScript(lang)

    return () => {
      document.querySelectorAll('script[data-jsonLd="true"]').forEach(el => el.remove());
      document.querySelectorAll('script[data-marge="true"]').forEach(el => el.remove());
    }
  }, [lang]);

  return (
    <>
      <header
        className={`${styles.header} ${styles[`${lang}`]} ${
          styles[`${productName}`]
        }`}
      >
        <div className={styles.products}>
          <div
            className={`${styles.productsBtn} ${
              isProductOpen && styles.adjust
            }`}
            onClick={handleProductOpen}
          >
            <span
              className={`${styles.hamburger} ${isProductOpen && styles.cross}`}
            ></span>
          </div>
          <ProductList
            t={t}
            displayedLinks={displayedLinks}
            isProductOpen={isProductOpen}
            handleProductOpen={handleProductOpen}
            productName={productName}
          />
        </div>

        {isH1Logo ? (
          <h1 className={styles.logo}>
            <a href={t(`links.${productName}`)} className={styles.logoLink}>
              <HeadLogo lang={lang} product={productName} />
            </a>
          </h1>
        ) : (
          <div className={styles.logo}>
            <a href={t(`links.${productName}`)} className={styles.logoLink}>
              <HeadLogo lang={lang} product={productName} />
            </a>
          </div>
        )}

        {/* <!-- TODO: 各プロダクト自由領域(center) --> */}
        {content?.center && (
          <div className={styles.contentCenter}>{content.center}</div>
        )}

        {/* <!-- TODO: 各プロダクト自由領域(right) --> */}
        {content?.right && (
          <div className={styles.contentRight}>{content.right}</div>
        )}

        <div className={styles.menu} ref={userMenuRef}>
          <div className={styles.menuBtn} onClick={handleUserOpen}>
            <span
              className={`${styles.menuBtnIcon} ${
                isUserOpen && styles.pink
              } icon-user`}
            ></span>
          </div>
          {isUserOpen && user ? (
            <UserMenu user={user} t={t} menu={menu} logout={logout} />
          ) : isUserOpen && !user ? (
            <UserNotLogInMenu
              t={t}
              optionalSignupQuery={optionalSignupQuery}
              notLoginMenu={notLoginMenu}
            />
          ) : null}
        </div>
      </header>
    </>
  );
};
