import ActivityCNDesktop from "../../../assets/images/logos/activity/cn_wamazing_play_h.svg";
import ActivityTWHKDesktop from "../../../assets/images/logos/activity/tw_hk_wamazing_play_h.svg";
import ActivityDesktop from "../../../assets/images/logos/activity/wamazing_play_h.svg";
import ActivityJPDesktop from "../../../assets/images/logos/activity/wamazing_play_tokyu.svg";
import DiscoverTWHKDesktop from "../../../assets/images/logos/discover/tw_hk_wamazing_discover_h.svg";
import DiscoverDesktop from "../../../assets/images/logos/discover/wamazing_discover_h.svg";
import KaimonoCNDesktop from "../../../assets/images/logos/kaimono/cn_wamazing_shop_h.svg";
import KaimonoTWHKDesktop from "../../../assets/images/logos/kaimono/tw_hk_wamazing_shop_h.svg";
import KaimonoDesktop from "../../../assets/images/logos/kaimono/wamazing_shop_h.svg";
import SnowCNDesktop from "../../../assets/images/logos/snow/cn_wamazing_snow_h.svg";
import SnowTWHKDesktop from "../../../assets/images/logos/snow/tw_hk_wamazing_snow_h.svg";
import SnowDesktop from "../../../assets/images/logos/snow/wamazing_snow_h.svg";
import TicketCNDesktop from "../../../assets/images/logos/ticket/cn_wamazing_transport_h.svg";
import TicketTWHKDesktop from "../../../assets/images/logos/ticket/tw_hk_wamazing_transport_h.svg";
import TicketDesktop from "../../../assets/images/logos/ticket/wamazing_transport_h.svg";
import WAmazingCNDesktop from "../../../assets/images/logos/wamazing/cn_wamazing_h.svg";
import WAmazingTWHKDesktop from "../../../assets/images/logos/wamazing/tw_hk_wamazing_h.svg";
import WAmazingDesktop from "../../../assets/images/logos/wamazing/wamazing_h.svg";
import YadoCNDesktop from "../../../assets/images/logos/yado/cn_wamazing_stay_h.svg";
import YadoHKDesktop from "../../../assets/images/logos/yado/hk_wamazing_stay_h.svg";
import YadoTWDesktop from "../../../assets/images/logos/yado/tw_wamazing_stay_h.svg";
import YadoDesktop from "../../../assets/images/logos/yado/wamazing_stay_h.svg";
import { LanguagesType } from "../../../models/languagesType";
import { WAProduct } from "../../../utils/productType";
import styles from "../../header/Header.module.scss";

const defaultMap = {
  wamazing: {
    desktop: WAmazingDesktop,
    mobile: WAmazingDesktop,
  },
  insurance: {
    desktop: WAmazingDesktop,
    mobile: WAmazingDesktop,
  },
  activity: {
    desktop: ActivityDesktop,
    mobile: ActivityDesktop,
  },
  discover: {
    desktop: DiscoverDesktop,
    mobile: DiscoverDesktop,
  },
  kaimono: {
    desktop: KaimonoDesktop,
    mobile: KaimonoDesktop,
  },
  snow: {
    desktop: SnowDesktop,
    mobile: SnowDesktop,
  },
  ticket: {
    desktop: TicketDesktop,
    mobile: TicketDesktop,
  },
  yado: {
    desktop: YadoDesktop,
    mobile: YadoDesktop,
  },
};

const JAMap = {
  wamazing: {
    desktop: WAmazingDesktop,
    mobile: WAmazingDesktop,
  },
  insurance: {
    desktop: WAmazingDesktop,
    mobile: WAmazingDesktop,
  },
  activity: {
    desktop: ActivityJPDesktop,
    mobile: ActivityDesktop,
  },
  discover: {
    desktop: DiscoverDesktop,
    mobile: DiscoverDesktop,
  },
  kaimono: {
    desktop: KaimonoDesktop,
    mobile: KaimonoDesktop,
  },
  snow: {
    desktop: SnowDesktop,
    mobile: SnowDesktop,
  },
  ticket: {
    desktop: TicketDesktop,
    mobile: TicketDesktop,
  },
  yado: {
    desktop: YadoDesktop,
    mobile: YadoDesktop,
  },
};

const TWMap = {
  wamazing: {
    desktop: WAmazingTWHKDesktop,
    mobile: WAmazingTWHKDesktop,
  },
  insurance: {
    desktop: WAmazingTWHKDesktop,
    mobile: WAmazingTWHKDesktop,
  },
  activity: {
    desktop: ActivityTWHKDesktop,
    mobile: ActivityTWHKDesktop,
  },
  discover: {
    desktop: DiscoverTWHKDesktop,
    mobile: DiscoverTWHKDesktop,
  },
  kaimono: {
    desktop: KaimonoTWHKDesktop,
    mobile: KaimonoTWHKDesktop,
  },
  snow: {
    desktop: SnowTWHKDesktop,
    mobile: SnowTWHKDesktop,
  },
  ticket: {
    desktop: TicketTWHKDesktop,
    mobile: TicketTWHKDesktop,
  },
  yado: {
    desktop: YadoTWDesktop,
    mobile: YadoTWDesktop,
  },
};

const HKMap = {
  wamazing: {
    desktop: WAmazingTWHKDesktop,
    mobile: WAmazingTWHKDesktop,
  },
  insurance: {
    desktop: WAmazingTWHKDesktop,
    mobile: WAmazingTWHKDesktop,
  },
  activity: {
    desktop: ActivityTWHKDesktop,
    mobile: ActivityTWHKDesktop,
  },
  discover: {
    desktop: DiscoverTWHKDesktop,
    mobile: DiscoverTWHKDesktop,
  },
  kaimono: {
    desktop: KaimonoTWHKDesktop,
    mobile: KaimonoTWHKDesktop,
  },
  snow: {
    desktop: SnowTWHKDesktop,
    mobile: SnowTWHKDesktop,
  },
  ticket: {
    desktop: TicketTWHKDesktop,
    mobile: TicketTWHKDesktop,
  },
  yado: {
    desktop: YadoHKDesktop,
    mobile: YadoHKDesktop,
  },
};

const CNMap = {
  wamazing: {
    desktop: WAmazingCNDesktop,
    mobile: WAmazingCNDesktop,
  },
  insurance: {
    desktop: WAmazingCNDesktop,
    mobile: WAmazingCNDesktop,
  },
  activity: {
    desktop: ActivityCNDesktop,
    mobile: ActivityCNDesktop,
  },
  discover: {
    desktop: DiscoverDesktop,
    mobile: DiscoverDesktop,
  },
  kaimono: {
    desktop: KaimonoCNDesktop,
    mobile: KaimonoCNDesktop,
  },
  snow: {
    desktop: SnowCNDesktop,
    mobile: SnowCNDesktop,
  },
  ticket: {
    desktop: TicketCNDesktop,
    mobile: TicketCNDesktop,
  },
  yado: {
    desktop: YadoCNDesktop,
    mobile: YadoCNDesktop,
  },
};

const LanguageMap = {
  en: defaultMap,
  ja: JAMap,
  zh_tw: TWMap,
  zh_hk: HKMap,
  zh_cn: CNMap,
  au: defaultMap,
  kr: defaultMap
};

const HeadLogo = ({ lang, product }: { lang: string; product: WAProduct }) => {
  type imageObject = {
    src: string;
    height: number;
    width: number;
  };

  const changeSrc = (val: string | imageObject): string => {
    if (typeof val === "object") {
      return val.src;
    }
    return val;
  };
  const isActivityJa = () => {
    return lang === "ja" && product === "activity";
  };
  return (
    <picture
      className={`${styles.logoPicture} ${
        isActivityJa() ? styles.activityJa : ""
      }`}
    >
      <source
        media="(max-width: 576px)"
        srcSet={changeSrc(
          LanguageMap[lang as LanguagesType][product]["mobile"]
        )}
      />
      <source
        media="(min-width: 577px)"
        srcSet={changeSrc(
          LanguageMap[lang as LanguagesType][product]["desktop"]
        )}
      />
      <img
        className={`${styles.logoImg} ${
          isActivityJa() ? styles.activityJa : ""
        }`}
        src={changeSrc(LanguageMap[lang as LanguagesType][product]["desktop"])}
        alt="WAmazing"
      />
      {isActivityJa() && (
        <span className={styles.logoImg__jpTokyuText}>
          Supported by 東急(株)
        </span>
      )}
    </picture>
  );
};

export default HeadLogo;
