import { LanguagesType } from "../models/languagesType";

export const getTranslation = (language: LanguagesType) => {
  const t = (val: string): string => {
    const formatVal = (jaData: any) => {
      let newVal: string[] = [];

      if (val.indexOf(".") !== -1) {
        const words = val.split(".");
        newVal.push(words[0]);
        newVal.push(words[1]);
        return jaData[newVal[0]][newVal[1]];
      } else {
        return jaData[val];
      }
    };

    switch (language) {
      case "ja":
        const jaData = require("../assets/translations/ja.json");
        return formatVal(jaData);
      case "en":
        const enData = require("../assets/translations/en.json");
        return formatVal(enData);
      case "zh_tw":
        const twData = require("../assets/translations/zh-TW.json");
        return formatVal(twData);
      case "zh_hk":
        const hkData = require("../assets/translations/zh-HK.json");
        return formatVal(hkData);
      case "zh_cn":
        const cnData = require("../assets/translations/zh-CN.json");
        return formatVal(cnData);
      case "au":
        const auData = require("../assets/translations/au.json");
        return formatVal(auData);
      default:
        const defaultData = require("../assets/translations/zh-TW.json");
        return formatVal(defaultData);
    }
  };
  return { t };
};
