const baiduTongjiTracker = {
  trackPageView(url: string) {
    _hmt.push(['_trackPageview', url]);
  },

  trackEvent(action: string, label: string, value: number) {
    _hmt.push(['_trackEvent', 'WebYado', action, label, value]);
  },
};

export default baiduTongjiTracker;
