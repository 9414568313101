import * as React from 'react';
import { when } from 'mobx';
import { observer } from 'mobx-react';

import classNames from 'classnames';
import CouponStore, {
  FeaturedCoupon,
  FeaturedCouponShowPattern,
  FeaturedCouponShowPatternType,
} from '../../../models/mobx/stores/CouponStore';
import AuthUserStore from '../../../models/mobx/stores/AuthUserStore';
import i18n from '../../../R/string/i18n';
import { Breakpoints, Colors } from '../../../shared/styles/StyleConst';
import { registrationUrl, wechatRegistrationUrl } from '../../../shared/utils/url_helper';
import { trackEvent } from '../../../models/trackings/Tracking';
import { pageEvents, actionEvents } from '../../../models/trackings/trackingEvents';
import { highlightTextPartially } from '../../../helpers/dom_helper';
import assets from '../../../R/assets';
import ENV from '../../../constants/ENV';
import { isCnWebBrowser, isWechat } from '../../../helpers/browser_helper';
import { redirectToQrLink } from '../../../helpers/router_helper';
import { numberWithCommas } from '../../../legacy/shared/utils/string';
import { isEnglish } from '../../../config/locales/i18n';

const BANNER_COLORS = {
  mutedText: '#fff9f1',
  highlightedText: '#ffffff',
  background: '#e16846',
  border: '#fcc88c',
};

function calculateRemainingDateTime(
  endDate: Date,
): {
  diff: number;
  d: number;
  h: number;
  m: number;
  s: number;
} {
  const year = endDate.getFullYear();
  const month = endDate.getMonth();
  const date = endDate.getDate();
  const hour = endDate.getHours();
  const minute = endDate.getMinutes();

  const timeZoneOffset = 1000 * 60 * 60 * 9;
  const cutOffDate = new Date(Date.UTC(year, month, date, hour, minute, 0) - timeZoneOffset);
  const now = new Date();
  const diff = cutOffDate.getTime() - now.getTime();
  const d = Math.floor(diff / (1000 * 60 * 60 * 24));
  const h = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const m = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const s = Math.floor((diff % (1000 * 60)) / 1000);
  return {
    diff,
    d,
    h,
    m,
    s,
  };
}

const CountDown = ({ endDate }: { endDate: Date }) => {
  const [countDownText, setCountDownText] = React.useState('');

  React.useEffect(() => {
    const timer = setInterval(() => {
      const { diff, d, h, m, s } = calculateRemainingDateTime(endDate);

      if (diff < 0) {
        clearInterval(timer);
        setCountDownText('expired'); // TODO: 翻訳文言にする
      } else if (d >= 1) {
        setCountDownText(
          i18n.webYadoCountDownDateTime({
            day: d,
            hour: h,
            minute: m,
            second: s,
          }),
        );
      } else {
        setCountDownText(
          i18n.webYadoCountDownTime({
            hour: h,
            minute: m,
            second: s,
          }),
        );
      }
    }, 1000);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="count-down">{countDownText}</div>
      <style jsx>
        {`
          .count-down {
            font-size: 12px;
            line-height: 1.23;
            font-weight: bold;
            min-width: 130px;
          }
          @media screen and (max-width: ${Breakpoints.xsMax}) {
            .count-down {
              font-weight: 400;
            }
          }
        `}
      </style>
    </React.Fragment>
  );
};

function campaignDescriptionByCouponId(couponId: number): string {
  if (couponId === ENV.COUPON_IDS.MISASA_KINOSAKI_KYOTO_201911) {
    return i18n.webYadoCouponBannerOnlyMisasaKinosakiKyoto();
  }

  return '';
}

function onClickBanner(
  authUser: AuthUserStore | Null,
  featuredCouponShowPattern: FeaturedCouponShowPatternType,
  isEnabledClickBanner: boolean,
) {
  trackEvent(actionEvents.COUPON_BANNER_CLICKED, {
    showPattern: featuredCouponShowPattern,
  });

  if (featuredCouponShowPattern === FeaturedCouponShowPattern.SHOW_WHEN_SIGNED_IN) {
    if (isEnabledClickBanner) {
      const url = '';
      window.open(url, '_blank');
    }
    return;
  }

  if (featuredCouponShowPattern === FeaturedCouponShowPattern.SHOW_WHEN_NOT_SIGNED_IN) {
    if (isCnWebBrowser()) {
      redirectToQrLink();
    } else if (isWechat()) {
      if (authUser) {
        authUser.signOut();
      }
      window.location.href = wechatRegistrationUrl(window.location.href);
    } else {
      window.location.href = registrationUrl(window.location.href);
    }
  }
}

function highlightNumber(text: string): React.ReactNode {
  return highlightTextPartially('\\d+', text, BANNER_COLORS.highlightedText, {
    fontWeight: 'normal',
    fontSize: '2.5em',
    verticalAlign: '-2px',
    fontFamily: '"Apple LiGothic","Trebuchet MS"',
  });
}

// 5%OFFなら95折、10%OFFなら9折になるように数字を調整する
function convertToZhe(discountRate: number): string {
  const percent = String((1 - discountRate) * 100);

  if (percent.endsWith('0')) {
    return percent.slice(0, -1);
  }

  return percent;
}

function checkIsShowFeaturedCoupon(featuredCoupon: FeaturedCoupon, canShowFeaturedCoupon: boolean) {
  const { consumeLimitReached } = featuredCoupon.couponObj;
  if (consumeLimitReached) return false;

  return canShowFeaturedCoupon;
}

const FeaturedCouponBanner = ({
  authUserStore,
  couponStore,
}: {
  authUserStore: AuthUserStore | Null;
  couponStore: CouponStore;
}) => {
  const {
    canShowFeaturedCoupon,
    featuredCoupon,
    featuredCouponShowPattern,
    featuredCouponCountDownEndDate,
  } = couponStore;
  when(
    () => canShowFeaturedCoupon,
    () => {
      trackEvent(pageEvents.COUPON_BANNER_SHOWN, {
        showPattern: featuredCouponShowPattern,
      });
    },
  );

  if (!featuredCoupon) return null;

  const isEnabledClickBanner = false;
  const isShowFeaturedCoupon = checkIsShowFeaturedCoupon(featuredCoupon, canShowFeaturedCoupon);

  const isSignedIn = featuredCouponShowPattern === FeaturedCouponShowPattern.SHOW_WHEN_SIGNED_IN;
  const isNotSignedIn =
    featuredCouponShowPattern === FeaturedCouponShowPattern.SHOW_WHEN_NOT_SIGNED_IN;
  const campaignDescription = campaignDescriptionByCouponId(featuredCoupon.couponObj.couponId);
  const bannerClass = classNames('featured-coupon-banner', {
    isSignedIn: isSignedIn && !isEnabledClickBanner,
  });

  return (
    <button
      type="button"
      className={bannerClass}
      onClick={() => onClickBanner(authUserStore, featuredCouponShowPattern, isEnabledClickBanner)}
      disabled={isSignedIn && !isEnabledClickBanner}
    >
      <div className="coupon-banner-container d-flex justify-content-around align-items-center flex-wrap flex-md-nowrap">
        <div className={`${isEnglish() ? 'd-lg-flex' : 'd-sm-flex'} align-items-center d-block`}>
          {isNotSignedIn && (
            <React.Fragment>
              <div className="bordered-label">{i18n.webYadoCouponBannerSignupNow()}</div>
              {campaignDescription && (
                <div className="plain-text ml-1 ml-sm-2">{campaignDescription}</div>
              )}
            </React.Fragment>
          )}
          <div className="d-flex align-items-center justify-content-center">
            {isSignedIn && <div className="font-weight-bold">{i18n.homeCouponLabel()}</div>}
            <div className="value font-weight-bold mx-2">
              {featuredCoupon.couponObj.discountRate && (
                <React.Fragment>
                  {highlightNumber(
                    i18n.webYadoZhe({
                      number: convertToZhe(featuredCoupon.couponObj.discountRate),
                    }),
                  )}
                </React.Fragment>
              )}
              {featuredCoupon.couponObj.discountAmount && (
                <React.Fragment>
                  <div className="d-md-flex align-items-center d-block">
                    JPY
                    <span className="discountAmount">
                      {numberWithCommas(featuredCoupon.couponObj.discountAmount)}
                    </span>
                    {isNotSignedIn && (
                      <span className="pl-1 plain-text font-weight-bold">
                        {i18n.webYadoCoupon()}
                      </span>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          {isSignedIn && (
            <React.Fragment>
              {campaignDescription && (
                <div className="bordered-label mr-1 mr-sm-2">{campaignDescription}</div>
              )}
              <div className="default-text plain-text mx-2">
                <p className="my-1 d-none d-lg-block">{i18n.webYadoCouponYouAlreadyHaveSuffix()}</p>
                <p className="label">{featuredCoupon.couponObj.label}</p>
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="d-flex align-items-center">
          {isSignedIn && (
            <React.Fragment>
              <div className="consume-limit d-flex align-items-center">
                {i18n.webYadoHeaderCouponLimitedFirst()}
                <div className="mx-2 separator" />
                {i18n.webYadoHeaderCouponConsumeLimitPeople({
                  consumeLimit: numberWithCommas(featuredCoupon.couponObj.consumeLimit),
                })}
              </div>
            </React.Fragment>
          )}
          <div className="remaining-time d-flex align-items-center mr-3">
            <img className="clock mr-1 mr-sm-2" src={assets.images.campaignClock} alt="clock" />
            <div className="d-none d-lg-block">{i18n.webYadoTimeRemaining()}</div>
            <div className="mx-2 separator d-none d-lg-block" />
            {featuredCouponCountDownEndDate && (
              <CountDown endDate={featuredCouponCountDownEndDate} />
            )}
          </div>
        </div>
        {isNotSignedIn && (
          <div
            style={{
              position: 'absolute',
              right: '15px',
            }}
          >
            <div className="right-arrow">{'>'}</div>
          </div>
        )}
      </div>
      <style jsx>
        {`
          .featured-coupon-banner {
            width: 100%;
            transition: height 250ms ease-in-out, background-color 0.2s ease-in-out;
            height: ${isShowFeaturedCoupon ? '64px' : 0};
            overflow: hidden;
            display: ${isShowFeaturedCoupon ? 'flex' : 'none'};
            align-items: center;
            color: ${BANNER_COLORS.mutedText};
            background-color: ${BANNER_COLORS.background};
            cursor: ${isSignedIn && !isEnabledClickBanner ? 'default' : 'pointer'};
            font-size: 15px;
            white-space: nowrap;
          }
          .featured-coupon-banner.isSignedIn,
          .featured-coupon-banner:hover {
            color: ${Colors.white};
            text-decoration: none;
            background-color: ${BANNER_COLORS.background};
          }
          .coupon-banner-container {
            width: 100%;
            height: 100%;
          }
          .value {
            color: ${BANNER_COLORS.highlightedText};
            font-size: 1.1em;
            line-height: 1;
            white-space: nowrap;
          }
          .discountAmount {
            letter-spacing: 1.1px;
            font-size: 32px;
            margin-left: 6px;
            vertical-align: -5px;
          }
          .default-text p {
            text-align: start;
          }
          .label {
            margin: 4px 0;
          }
          .plain-text {
            font-size: 12px;
            line-height: 1.07;
          }
          .consume-limit {
            margin-right: 20px;
            font-weight: bold;
            font-size: 12px;
            line-height: 1.23;
          }
          .bordered-label {
            font-size: 12px;
            font-weight: 600;
            line-height: 1.33;
            letter-spacing: 1.89px;
            padding: 6px 8px;
            border: solid 1px ${BANNER_COLORS.border};
          }
          .clock {
            width: 20px;
            height: 20px;
          }
          .remaining-time {
            font-size: 12px;
            line-height: 1.23;
            font-weight: bold;
            padding: 2px;
          }
          .separator {
            height: 12px;
            width: 1px;
            background-color: rgba(255, 255, 255, 0.25);
          }
          .right-arrow {
            font-size: 12px;
            margin-top: -4px;
            transform: scale(0.9, 1.7);
            line-height: 1;
          }
          @media screen and (max-width: ${Breakpoints.smMax}) {
            .featured-coupon-banner {
              font-size: 13px;
            }
            .coupon-banner-container {
              flex-direction: ${isWechat() || isNotSignedIn ? 'row' : 'column'};
            }
            .discountAmount {
              letter-spacing: 1.1px;
              font-size: 22.5px;
              margin-left: 6px;
              vertical-align: -5px;
            }
            .clock {
              width: 13px;
              height: 13px;
            }
            .bordered-label {
              padding: 3px;
            }
          }
          @media screen and (max-width: ${Breakpoints.xsMax}) {
            .featured-coupon-banner {
              height: 78px;
              font-size: 10.5px;
            }
            .default-text p {
              text-align: center;
              margin-left: -8px;
            }
            .label {
              margin: 6px 0 0 0;
            }
            .remaining-time,
            .consume-limit {
              font-weight: 400;
              margin-right: 8px;
            }
          }
        `}
      </style>
    </button>
  );
};

export default observer(FeaturedCouponBanner);
