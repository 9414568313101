import { LanguagesType } from '../models/languagesType'

export const injectMargeGroupMeasurementTag = () => {
  const existingScripts = document.querySelectorAll('script[data-marge="true"]')
  existingScripts.forEach((script) => script.remove())
  const script = document.createElement('script')
  script.setAttribute('data-marge', 'true')
  script.dataset.uqid = 'c5e3c193y273353e'
  script.dataset.gid = '8'
  script.id = `afadfpc-c5e3c193y273353egid8-${Date.now()}`
  script.src = `//af-wamazing.catsys.jp/fpc/cookie_js.php?scriptId=${encodeURIComponent(script.id)}`
  document.head.appendChild(script)
}

export const injectJsonLdScript = (lang: LanguagesType) => {
  const existingScripts = document.querySelectorAll('script[data-jsonLd="true"]')
  existingScripts.forEach((script) => script.remove())
  const jsonLdData = generateJsonLdData(lang)
  const scriptTag = document.createElement('script')
  scriptTag.setAttribute('data-jsonLd', 'true')
  scriptTag.type = 'application/ld+json'
  scriptTag.text = JSON.stringify(jsonLdData)
  document.head.appendChild(scriptTag)
}

const generateJsonLdData = (lang: LanguagesType) => ({
  '@context': 'http://schema.org',
  '@type': 'SoftwareApplication',
  name: 'WAmazing',
  url: getUrl(lang),
  operatingSystem: 'Web',
  applicationCategory: 'Online Travel Agency',
  applicationSubCategory: 'Travel',
  countriesSupported: 'TW, HK, CN, US',
  offers: {
    '@type': 'Offer',
    price: '0.00',
    priceCurrency: 'JPY'
  },
  publisher: {
    '@type': 'Corporation',
    name: 'WAmazing',
    url: 'https://corp-global.wamazing.com/'
  },
  contentLocation: {
    '@type': 'Place',
    name: 'Japan'
  },
  countryOfOrigin: 'Japan',
  inLanguage: 'zh-TW, zh-HK, zh-CN, en'
})

const getUrl = (lang: LanguagesType) => {
  switch (lang) {
    case 'ja':
      return 'https://tw.wamazing.com/'
    case 'zh_tw':
      return 'https://tw.wamazing.com/'
    case 'zh_hk':
      return 'https://hk.wamazing.com/'
    case 'zh_cn':
      return 'https://wamazing-cn.com/'
    default:
      return 'https://www.wamazing.com/'
  }
}
