export type DisplayLinksType = {
  [key: string]: boolean;
};

export const displayLinks = {
  ja: {
    top: false,
    yado: false,
    activity: true,
    snow: true,
    ticket: false,
    kaimono: false,
    discover: false,
    sim: false,
    insurance: false,
    app: true,
    appManual: false,
    campaigns: false,
  },
  en: {
    top: false,
    yado: true,
    activity: true,
    snow: true,
    ticket: true,
    kaimono: true,
    discover: true,
    sim: false,
    insurance: false,
    app: true,
    appManual: false,
    campaigns: false,
  },
  zh_tw: {
    top: true,
    yado: true,
    activity: true,
    snow: true,
    ticket: true,
    kaimono: true,
    discover: true,
    sim: true,
    insurance: false,
    app: true,
    appManual: true,
    campaigns: true,
  },
  zh_hk: {
    top: true,
    yado: true,
    activity: true,
    snow: true,
    ticket: true,
    kaimono: true,
    discover: true,
    sim: true,
    insurance: false,
    app: true,
    appManual: true,
    campaigns: true,
  },
  zh_cn: {
    top: false,
    yado: true,
    activity: true,
    snow: true,
    ticket: true,
    kaimono: true,
    discover: false,
    sim: false,
    insurance: false,
    app: false,
    appManual: false,
    campaigns: false,
  },
  au: {
    top: false,
    yado: true,
    activity: true,
    snow: true,
    ticket: true,
    kaimono: true,
    discover: true,
    sim: false,
    insurance: false,
    app: true,
    appManual: false,
    campaigns: false,
  },
  kr: {
    top: false,
    yado: false,
    activity: false,
    snow: false,
    ticket: false,
    kaimono: false,
    discover: false,
    sim: false,
    insurance: false,
    app: true,
    appManual: false,
    campaigns: false,
  },
};
