import { ReactNode } from "react";
import styles from "./Footer.module.scss";

type Props = {
  href: string;
  title?: string;
  productName?: string;
  children?: ReactNode;
  ariaLabel?: string;
};

export const FooterList: React.FC<Props> = ({
  href,
  title,
  productName,
  children,
  ariaLabel
}) => {
  return (
    <li className={styles.list}>
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.anchor}
        aria-label={ariaLabel}
      >
        {title} {productName} {children}
      </a>
    </li>
  );
};
