export type SupportedLanguageId = 'english' | 'mandarin' | 'cantonese';

class SupportedLanguage {
  constructor(
    readonly supportedLanguageId: SupportedLanguageId,
    readonly supportedLanguageName: string,
  ) {}
}

export default SupportedLanguage;
