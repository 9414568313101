import qs from 'qs';
import Router from 'next/router';

import { LinkProps } from '../../../shared/utils/url_helper';
import { OldSearchCondition } from './searchUtil';

const ARRAY_FORMAT: { arrayFormat: 'indices' } = {
  arrayFormat: 'indices',
};
const TOP = {
  as: '/yado',
  href: '/yado',
} as const;

export function linkTop(): LinkProps {
  return TOP;
}
export function createYadoDetailLink(
  yadoId: string,
  searchCondition?: OldSearchCondition,
): LinkProps {
  let asQuery = '';

  if (searchCondition) {
    asQuery = `?${qs.stringify(searchCondition, ARRAY_FORMAT)}`;
  }

  const hrefQuery = qs.stringify(
    {
      yadoId,
    },
    ARRAY_FORMAT,
  );
  const as = `/yado/yados/${yadoId}${asQuery}`;
  const href = `/yado/yados/show?${hrefQuery}`;
  return {
    as,
    href,
  };
}
export function linkBookings(): LinkProps {
  const as = '/yado/bookings';
  const href = '/yado/bookings';
  return {
    as,
    href,
  };
}
export function createYadoListLink(
  searchMode: string,
  searchCondition: OldSearchCondition,
): LinkProps {
  switch (searchMode) {
    case 'spot': {
      return linkSpotBySearchCondition(searchCondition);
    }

    case 'prefecture': {
      return linkPrefectureBySearchCondition(searchCondition);
    }

    case 'city': {
      return linkCityBySearchCondition(searchCondition);
    }

    case 'feature': {
      return linkFeatureBySearchCondition(searchCondition);
    }

    default: {
      throw new Error('no match searchMode');
    }
  }
}
function linkPrefectureBySearchCondition(searchCondition: OldSearchCondition): LinkProps {
  const searchParams = {
    ...searchCondition,
  };
  const { prefecture } = searchParams;
  delete searchParams.spot;
  delete searchParams.city;
  delete searchParams.feature;
  const asQuery = `?${qs.stringify(searchParams, ARRAY_FORMAT)}`;
  const hrefQuery = qs.stringify(
    {
      prefecture,
    },
    ARRAY_FORMAT,
  );
  const as = `/yado/prefectures/${prefecture}${asQuery}`;
  const href = `/yado/prefectures?${hrefQuery}`;
  return {
    as,
    href,
  };
}

function linkCityBySearchCondition(searchCondition: OldSearchCondition): LinkProps {
  const searchParams = {
    ...searchCondition,
  };
  const { prefecture, city } = searchParams;
  delete searchParams.spot;
  delete searchParams.feature;
  const asQuery = `?${qs.stringify(searchParams, ARRAY_FORMAT)}`;
  const hrefQuery = qs.stringify(
    {
      prefecture,
      city,
    },
    ARRAY_FORMAT,
  );
  const as = `/yado/prefectures/${prefecture}/cities/${city}${asQuery}`;
  const href = `/yado/cities?${hrefQuery}`;
  return {
    as,

    href,
  };
}

function linkSpotBySearchCondition(searchCondition: OldSearchCondition): LinkProps {
  const searchParams = {
    ...searchCondition,
  };
  const { spot } = searchParams;
  delete searchParams.prefecture;
  delete searchParams.city;
  delete searchParams.feature;
  const asQuery = `?${qs.stringify(searchParams, ARRAY_FORMAT)}`;
  const hrefQuery = qs.stringify(
    {
      spot,
    },

    ARRAY_FORMAT,
  );
  const as = `/yado/spots/${spot}${asQuery}`;
  const href = `/yado/spots?${hrefQuery}`;
  return {
    as,
    href,
  };
}

function linkFeatureBySearchCondition(searchCondition: OldSearchCondition): LinkProps {
  const searchParams = {
    ...searchCondition,
  };
  const { feature } = searchParams;
  delete searchParams.prefecture;
  delete searchParams.city;
  delete searchParams.spot;

  const asQuery = `?${qs.stringify(searchParams, ARRAY_FORMAT)}`;
  const hrefQuery = qs.stringify(
    {
      feature,
    },
    ARRAY_FORMAT,
  );
  const as = `/yado/features/${feature}${asQuery}`;
  const href = `/yado/features?${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function linkPrefecture(prefecture: string | null | undefined): LinkProps {
  if (!prefecture) return TOP;
  const hrefQuery = qs.stringify({
    prefecture,
  });
  const as = `/yado/prefectures/${prefecture}`;
  const href = `/yado/prefectures?${hrefQuery}`;
  return {
    as,
    href,
  };
}
export function linkCity(
  prefecture: string | null | undefined,
  city: string | null | undefined,
): LinkProps {
  if (!prefecture || !city) return TOP;
  const hrefQuery = qs.stringify({
    prefecture,
    city,
  });
  const as = `/yado/prefectures/${prefecture}/cities/${city}`;
  const href = `/yado/cities?${hrefQuery}`;
  return {
    as,
    href,
  };
}
export function linkSpot(spot: string | null | undefined): LinkProps {
  if (!spot) return TOP;
  const hrefQuery = qs.stringify({
    spot,
  });
  const as = `/yado/spots/${spot}`;
  const href = `/yado/spots?${hrefQuery}`;
  return {
    as,
    href,
  };
}
export function linkFeature(feature: string | null | undefined): LinkProps {
  if (!feature) return TOP;
  const hrefQuery = qs.stringify({
    feature,
  });
  const as = `/yado/features/${feature}`;
  const href = `/yado/features?${hrefQuery}`;
  return {
    as,
    href,
  };
}
export function createPlanDetailLink(
  yadoId: string,
  planId: string,
  searchCondition?: OldSearchCondition,
): LinkProps {
  let asQuery = '';

  if (searchCondition) {
    asQuery = `?${qs.stringify(searchCondition, ARRAY_FORMAT)}`;
  }

  const hrefQuery = qs.stringify(
    {
      yadoId,
      planId,
    },

    ARRAY_FORMAT,
  );
  const as = `/yado/yados/${yadoId}/plans/${planId}${asQuery}`;
  const href = `/yado/plans?${hrefQuery}`;
  return {
    as,
    href,
  };
}
export function pushYadoListUrl(searchMode: string, searchCondition: OldSearchCondition) {
  const link = createYadoListLink(searchMode, searchCondition);

  Router.push(link.href, link.as);
}
export function replaceYadoListUrl(searchMode: string, searchCondition: OldSearchCondition) {
  const link = createYadoListLink(searchMode, searchCondition);
  Router.replace(link.href, link.as);
}

export function replaceYadoDetailUrl(yadoId: string, searchCondition: OldSearchCondition) {
  const link = createYadoDetailLink(yadoId, searchCondition);
  Router.replace(link.href, link.as);
}
